import { Box, Grid, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useState, useMemo } from 'react';
import { useTheme } from '@emotion/react';

// Component
import AdminLoanInquiryModal from 'components/Modal/AdminLoanInquiryModal';
import DocumentCheckList from 'components/LoanRequestDetails/DocumentChecklist';
import Loader from 'components/Loader';
import LoanRequestDetailsCard from 'components/LoanRequestDetails/LoanRequestDetailsCard';
import LoanRequestDetailsHeading from 'components/LoanRequestDetails/LoanRequestDetailsHeading';
import LoanRequestStatusBar from 'components/LoanRequestDetails/LoanRequestStatusBar';
import LoanOffersTable from 'components/LoanRequestDetails/LoanOffersTable';
import NewLoanRequestError from 'components/Error/NewLoanRequestError';
import RequestOfferModal from 'components/Modal/RequestOfferModal';
import { SecondaryButton, TertiaryButton } from 'components/Button/Button';
import TalkJsChatContainer from 'components/Chat/TalkJsChatContainer';

// Hooks
import useGetLoanRequestDetails from 'hooks/newLoanInquiries/useGetLoanRequestDetails';

// Our Utils
import { decodedLoanType } from 'shared/utils';

// Our Constants
import { LOAN_INQUIRY_DISABLED_ADD_LOAN_OFFER } from 'shared/constants/LoanInquiry/LoanInquiryDisabledAddLoanOffer';

// Local constants
const LOAN_INQUIRY_TYPES_WITH_DOCUMENTS = new Set([
	'New Mortgage Loan',
	'Mortgage Refinance Loan',
	'Cash Out Refinance Loan',
	'Reverse Mortgage Loan',
	'Commercial Loan',
	'HELOC Loan'
]);

function LoanRequestDetailsPage() {
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;

	const urlString = window.location.search;
	const urlParams = new URLSearchParams(urlString);
	const loanRequestId = urlParams.get('loanRequestId');

	const { data, isLoading, isSuccess, error } =
		useGetLoanRequestDetails(loanRequestId);

	const [isModalOpen, setIsModalOpen] = useState(false);

	const [isRequestOfferModalOpen, setIsRequestOfferModalOpen] =
		useState(false);

	const loanRequestType = useMemo(() => {
		if (isSuccess) {
			const { newLoanDetails } = data;
			const { offerType, tradelineType } = newLoanDetails;
			const formattedLoanType = decodedLoanType(tradelineType, offerType);
			return formattedLoanType;
		}

		return '';
	}, [data, isSuccess]);

	const isLoanRequestWithDocuments = useMemo(() => {
		return LOAN_INQUIRY_TYPES_WITH_DOCUMENTS.has(loanRequestType);
	}, [data]);

	const isAddOfferButtonDisabled = useMemo(() => {
		if (isSuccess) {
			const { newLoanDetails } = data;
			const { adminStatus } = newLoanDetails;


			return LOAN_INQUIRY_DISABLED_ADD_LOAN_OFFER.includes(adminStatus);
		}

		return false;
	}, [data, isSuccess]);

	if (error) {
		return <NewLoanRequestError />;
	}

	if (isLoading) {
		return (
			<>
				<LoanRequestDetailsHeading isLoading={isLoading} />
				<Grid
					container
					item
					xs={12}
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}
				>
					<Loader size={70} />
				</Grid>
			</>
		);
	}

	if (isSuccess) {
		const { newLoanDetails, newLoanOffers } = data;

		// Data pull off the endpoint response
		const {
			acceptedOfferId,
			advisorName,
			addressLine1,
			addressLine2,
			adminStatus,
			adminStatusText,
			city,
			currentRemainingTerm,
			employmentStatus,
			estimatedPurchaseValue,
			firstName,
			id,
			lastName,
			lastUpdated,
			loanAmount,
			loanTenure,
			offerType,
			primaryUse,
			propertyType,
			reasonForLoan,
			requesterId,
			state,
			timeUntilConstruction,
			tradelineType,
			totalAnnualIncome,
			totalAssetsValue,
			vehicleMileage,
			ownershipTenure,
			zipCode,
			clientId
		} = newLoanDetails;

		// Data used in this component
		const formattedLastUpdated = format(
			new Date(lastUpdated),
			'MM/dd/yyyy'
		);
		const clientName = `${firstName} ${lastName}`;
		const clientAddress = `${addressLine1} ${addressLine2} ${city} ${state}, ${zipCode}`;

		const safeClientAnnualIncome = +newLoanDetails?.totalAnnualIncome ?? 0;

		const clientData = {
			clientName,
			firstName,
			lastName,
			clientAddress,
			creditScore: newLoanDetails?.creditScore,
			stateLocation: newLoanDetails?.state,
			annualIncome: safeClientAnnualIncome
		};

		const loanRequestTradelineType = tradelineType;

		const isLoanOfferAccepted =
			acceptedOfferId !== 0 && acceptedOfferId !== null;

		return (
			<>
				<TalkJsChatContainer
					advisorId={requesterId}
					clientId={clientId}
					clientInfo={{ id: clientId, name: clientName }}
				/>

				<LoanRequestDetailsHeading
					advisorName={advisorName}
					clientData={clientData}
					lastUpdatedDate={formattedLastUpdated}
					loanRequestId={id}
					loanRequestStatus={adminStatus}
					loanRequestStatusText={adminStatusText}
					loanRequestType={loanRequestType}
					isLoanOfferAccepted={isLoanOfferAccepted}
				/>

				<Grid container>
					{/* Progress Bar */}
					<Grid item xs={12}>
						<LoanRequestStatusBar status={adminStatus} />
					</Grid>

					<Grid
						item
						xs={12}
						md={6}
						sx={{ marginBottom: 8, paddingRight: { xs: 0, md: 4 } }}
					>
						<LoanRequestDetailsCard
							city={city}
							currentRemainingTerm={currentRemainingTerm}
							employmentStatus={employmentStatus}
							estimatedPurchaseValue={estimatedPurchaseValue}
							loanAmount={loanAmount}
							loanTenure={loanTenure}
							isLoanOfferAccepted={isLoanOfferAccepted}
							offerType={offerType}
							ownershipTenure={ownershipTenure}
							propertyUse={primaryUse}
							propertyType={propertyType}
							reasonForLoan={reasonForLoan}
							state={state}
							timeUntilConstruction={timeUntilConstruction}
							tradelineType={tradelineType}
							totalAnnualIncome={totalAnnualIncome}
							totalAssetsValue={totalAssetsValue}
							vehicleMileage={vehicleMileage}
							zipCode={zipCode}
							newLoanDetails={newLoanDetails}
						/>
					</Grid>

					{isLoanRequestWithDocuments && (
						<DocumentCheckList
							clientId={clientId}
							loanRequestId={loanRequestId}
						/>
					)}

					<Grid container>
						<Typography
							component="h2"
							gutterBottom
							sx={{
								color: primary.indigo,
								display: 'inline-block',
								marginRight: 3
							}}
							variant="h2Gascogne"
						>
							Loan Offers
						</Typography>

						<Box sx={{ display: 'flex', gap: 2, height: 35 }}>
							<TertiaryButton
								disabled={
									isLoanOfferAccepted ||
									isAddOfferButtonDisabled
								}
								onClick={() => setIsModalOpen(true)}
							>
								Add Offer
							</TertiaryButton>
							<SecondaryButton
								disabled={!isLoanOfferAccepted}
								onClick={() => setIsRequestOfferModalOpen(true)}
							>
								Request Offer
							</SecondaryButton>
						</Box>

						<LoanOffersTable
							tradelineType={tradelineType}
							loanType={loanRequestType}
							loanOffers={newLoanOffers}
							isLoanOfferAccepted={isLoanOfferAccepted}
						/>
					</Grid>
				</Grid>

				<AdminLoanInquiryModal
					tradelineType={tradelineType}
					loanType={loanRequestType}
					isOpen={isModalOpen}
					setIsModalOpen={setIsModalOpen}
				/>

				{isLoanOfferAccepted && (
					<RequestOfferModal
						acceptedOfferId={acceptedOfferId}
						clientData={clientData}
						loanType={loanRequestTradelineType}
						newLoanOffers={newLoanOffers}
						offerType={offerType}
						state={state}
						tradelineType={tradelineType}
						// Modal Props
						isOpen={isRequestOfferModalOpen}
						setIsModalOpen={setIsRequestOfferModalOpen}
					/>
				)}
			</>
		);
	}
}

export default LoanRequestDetailsPage;
