const selectAdvisorDetails = (state) => {
	const { newLoanDetails } = state;

	// Advisor Specific Details

	const { advisorEmail, advisorName, companyName } = newLoanDetails;

	return {
		advisorEmail,
		advisorName,
		companyName
	};
};

export default selectAdvisorDetails;
