import { useMutation, useQueryClient } from 'react-query';

// our axios
import axiosInstance from 'services/API/API';

// Our Endpoints
import { saveNewLoanOfferUrl } from 'shared/api-urls';

// our query keys
import { LOAN_REQUEST } from 'shared/query-keys';

function useMutateAddLoanOffer() {
	const queryClient = useQueryClient();
	return useMutation(
		async (newLoanOfferData) => {
			const response = await axiosInstance.post(
				saveNewLoanOfferUrl,
				newLoanOfferData
			);

			return response.data;
		},
		{
			onSuccess: async () => {
				// user is shown success lightbox
				await queryClient.invalidateQueries(LOAN_REQUEST);
			}
		}
	);
}

export default useMutateAddLoanOffer;
