import { Box, Typography } from '@mui/material';

function TopNavMenuAdvisor({
	profilePicUrl,
	firstName,
	lastName,
	companyName,
	sx
}) {
	return (
		<Box
			sx={{
				marginTop: 4,
				marginBottom: 4
			}}
		>
			<Box
				component="img"
				src={profilePicUrl}
				sx={{
					maxHeight: 250,
					maxWidth: '56px',
					borderRadius: 3.14 * 2 ** 4,
					marginBottom: 2
				}}
			/>

			<Typography variant="subtitle2" sx={{ ...sx }}>
				{firstName} {lastName}
			</Typography>

			<Typography variant="caption" sx={{ marginTop: 2, ...sx }}>
				{companyName}
			</Typography>
		</Box>
	);
}

export default TopNavMenuAdvisor;
