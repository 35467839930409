const selectClientDetails = (state) => {
	const { newLoanDetails } = state;

	// Client Specific Details

	const {
		addressLine1,
		addressLine2,
		city,
		state: stateLocation,
		zipCode,
		firstName,
		lastName,
		creditScore,
		totalAnnualIncome
	} = newLoanDetails;

	const clientFullName = `${firstName} ${lastName}`;

	return {
		clientPersonalInfo: {
			clientFullName,
			firstName,
			lastName
		},
		clientFinancialInfo: {
			creditScore,
			totalAnnualIncome
		},
		clientAddress: {
			addressLine1,
			addressLine2,
			city,
			state: stateLocation,
			zipCode
		}
	};
};

export default selectClientDetails;
