import { Box } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useCallback } from 'react';

// Our Components
import LoanDetailsDataBlock from 'components/PortableDocumentFormats/LoanDetailsDataBlock';
import PDFSection from 'components/PortableDocumentFormats/Section/PDFSection';
import PDFHeadingText from 'components/PortableDocumentFormats/Heading/PDFHeadingText';

// Our Hooks
import useGetLoanRequestDetails from 'hooks/newLoanInquiries/useGetLoanRequestDetails';

// Our Selectors
import selectLoanRequestDetails from 'selectors/LoanRequestDetails/selectLoanRequestDetails';

// Our Utils
import { dollarFormatter } from 'shared/utils';
import formatOfferType from 'shared/utils/formatting/LoanInquiry/formatOfferType';

const LoanRequestDetailsSection = () => {
	const SoraTheme = useTheme();
	const { indigo } = SoraTheme.palette.primary;

	const urlString = window.location.search;
	const urlParams = new URLSearchParams(urlString);
	const loanRequestId = urlParams.get('loanRequestId');

	const memoizedSelectLoanRequestDetails = useCallback(
		selectLoanRequestDetails,
		[]
	);

	const { data } = useGetLoanRequestDetails(
		loanRequestId,
		memoizedSelectLoanRequestDetails
	);

	const {
		additionalNotes,
		loanAmount,
		offerType,
		ownershipTenure,
		loanRequestType
	} = data;

	// Formatted
	const formattedOfferType = formatOfferType(offerType ?? 'NA');
	const formattedLoanAmount = dollarFormatter.format(loanAmount ?? 0);

	// With Fallback
	const ownershipTenureWithFallback = ownershipTenure ?? 'NA';

	const loanRequestDetailsData = [
		{ label: 'Loan Type', content: loanRequestType },
		{ label: 'Loan Amount', content: formattedLoanAmount },
		{ label: 'Loan Term', content: ownershipTenureWithFallback },
		{ label: 'Request Type', content: formattedOfferType },
		{ label: 'Additional Information', content: additionalNotes }
	];

	return (
		<Box
			sx={{
				marginTop: 2,
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'flex-start'
			}}
		>
			<PDFHeadingText
				headingVariant="h3Gascogne"
				headingText="Loan Request Details"
				sx={{ color: indigo, marginBottom: 4 }}
			/>

			<PDFSection sx={{ display: 'flex', gap: 8 }}>
				<LoanDetailsDataBlock detailsData={loanRequestDetailsData} />
			</PDFSection>
		</Box>
	);
};

export default LoanRequestDetailsSection;
