import { Box } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useCallback } from 'react';

// Our Components
import LoanDetailsDataBlock from 'components/PortableDocumentFormats/LoanDetailsDataBlock';
import PDFSection from 'components/PortableDocumentFormats/Section/PDFSection';
import PDFHeadingText from 'components/PortableDocumentFormats/Heading/PDFHeadingText';

// Our Hooks
import useGetLoanRequestDetails from 'hooks/newLoanInquiries/useGetLoanRequestDetails';

// Our Selectors
import selectLoanOfferDetails from 'selectors/LoanRequestDetails/selectLoanOfferDetails';

// Our Utils
import { dollarFormatter } from 'shared/utils';
import parseLoanTerm from 'shared/utils/parse/parseLoanTerm';

const LoanDetailsLoanOfferSection = () => {
	const SoraTheme = useTheme();
	const { indigo } = SoraTheme.palette.primary;

	const urlString = window.location.search;
	const urlParams = new URLSearchParams(urlString);
	const loanRequestId = urlParams.get('loanRequestId');

	const memoizedSelectLoanOfferDetails = useCallback(
		selectLoanOfferDetails,
		[]
	);

	const { data } = useGetLoanRequestDetails(
		loanRequestId,
		memoizedSelectLoanOfferDetails
	);

	const {
		lender,
		rate,
		monthlyPayment,
		loanTerm,
		soraFeeAmount,
		feeType,
		upfrontCost
	} = data;

	// Formatted
	const formattedMonthlyPayment = dollarFormatter.format(monthlyPayment ?? 0);
	const formattedSoraFee = dollarFormatter.format(soraFeeAmount ?? 0);
	const otherLenderFees = dollarFormatter.format(upfrontCost ?? 0);
	const parsedLoanTerm = parseLoanTerm(loanTerm);

	const loanOfferSelectedData = [
		{ label: 'Lender Name', content: lender },
		{ label: 'Annual Interest Rate', content: `${rate}%` },
		{ label: 'Monthly Payment', content: formattedMonthlyPayment },
		{ label: 'Interest Type', content: parsedLoanTerm?.type ?? 'NA' },
		{ label: 'Sora Fee', content: formattedSoraFee },
		{ label: 'Sora Fee Type', content: feeType },
		{ label: 'Other Lender Fees', content: otherLenderFees }
	];

	return (
		<Box
			sx={{
				marginTop: 2,
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'flex-start'
			}}
		>
			<PDFHeadingText
				headingVariant="h3Gascogne"
				headingText="Loan Offer"
				sx={{ color: indigo, marginBottom: 4 }}
			/>

			<PDFSection sx={{ display: 'flex', gap: 8 }}>
				<LoanDetailsDataBlock detailsData={loanOfferSelectedData} />
			</PDFSection>
		</Box>
	);
};

export default LoanDetailsLoanOfferSection;
