import { useMutation } from 'react-query';
import { getAuth, signOut } from 'firebase/auth';

// Points to landing page
const HOME_PAGE = process.env.REACT_APP_URL;

const useMutateSignOutUser = () => {
	const auth = getAuth();

	return useMutation(
		async () => {
			const res = await signOut(auth);
			// proxy call!
			return res;
		},
		{
			onSuccess: () => {
				window.location.href = HOME_PAGE;
			}
		}
	);
};

export default useMutateSignOutUser;
