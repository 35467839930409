import { Grid, Typography } from '@mui/material';

function RequestDetailsItem({ heading, value, isAddress, city, state }) {
	// Address is the exception to the pattern.
	if (isAddress)
		return (
			<Grid item xs={6} sx={{ marginBottom: 2 }}>
				<Typography
					variant="subtitle1"
					component="p"
					gutterBottom
					sx={{ fontWeight: 'bold', marginBottom: 1 }}
				>
					{heading ?? 'Address'}
				</Typography>
				<Typography
					variant="body2"
					component="p"
					gutterBottom
					sx={{ marginRight: 4, lineHeight: 1.3 }}
				>
					{city},&nbsp;
					{state}&nbsp;
				</Typography>
			</Grid>
		);

	return (
		<Grid item xs={6} sx={{ marginBottom: 2 }}>
			<Typography
				variant="subtitle1"
				component="p"
				gutterBottom
				sx={{ fontWeight: 'bold', marginBottom: 1 }}
			>
				{heading}
			</Typography>
			<Typography
				variant="body2"
				component="p"
				gutterBottom
				sx={{ marginRight: 4 }}
			>
				{value}
			</Typography>
		</Grid>
	);
}

export default RequestDetailsItem;
