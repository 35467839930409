import { Grid, Paper, Stack, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

// Our Components
import LoanRequestRelatedDocuments from 'components/Tasks/LoanRequestDetailsDocuments';

function DocumentCheckList({ loanRequestId, clientId }) {
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;

	return (
		<Grid item xs={12} md={6} sx={{ marginBottom: 8, paddingRight: 4 }}>
			<Paper
				elevation={3}
				square={false}
				component={Stack}
				sx={{
					borderRadius: '20px',
					padding: 4,
					flexDirection: 'column',
					gap: 2,
					boxSizing: 'border-box',
					height: '100%',
					position: 'relative'
				}}
			>
				<Typography
					variant="h2Gascogne"
					component="h2"
					gutterBottom
					sx={{ color: primary.indigo }}
				>
					Document Checklist
				</Typography>

				<Grid container>
					<Grid item xs={12}>
						<LoanRequestRelatedDocuments
							loanRequestId={loanRequestId}
							clientId={clientId}
						/>
					</Grid>
				</Grid>
			</Paper>
		</Grid>
	);
}

export default DocumentCheckList;
