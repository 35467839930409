import { isEmail } from 'validator';
/**
 *
 * @param {string} emailValue
 * @returns {boolean}
 */

function isValidEmail(emailValue) {
	if (!emailValue) return false;

	return isEmail(emailValue);
}

export default isValidEmail;
