import { useMutation, useQueryClient } from 'react-query';

// our axios
import axiosInstance from 'services/API/API';

// Our Endpoints
import { updateNewLoanInquiryStatusUrl } from 'shared/api-urls';

// our query keys
import {
	ADVISOR_LOAN_REQUESTS,
	ERROR_MESSAGE_DATA,
	LOAN_REQUEST
} from 'shared/query-keys';

// Our Constants
import { ADVISOR_ADMIN_MAPPING } from 'shared/constants';

function useMutateUpdateLoanRequestStatus() {
	const queryClient = useQueryClient();
	return useMutation(
		async ({ loanRequestId, loanRequestStatus }) => {
			const ADVISOR_STATUS = ADVISOR_ADMIN_MAPPING[loanRequestStatus];

			const response = await axiosInstance.patch(
				updateNewLoanInquiryStatusUrl,
				[
					{
						newLoanId: loanRequestId,
						adminStatus: loanRequestStatus,
						faStatus: ADVISOR_STATUS
					}
				]
			);

			return response.data;
		},
		{
			onSuccess: (loanRequestId) => {
				queryClient.refetchQueries({
					queryKey: LOAN_REQUEST,
					loanRequestId
				});
				queryClient.invalidateQueries(ADVISOR_LOAN_REQUESTS);
			}
		},
		{
			onError: (error) => {
				queryClient.setQueryData(ERROR_MESSAGE_DATA, error);
			}
		}
	);
}

export default useMutateUpdateLoanRequestStatus;
