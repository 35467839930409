import { Box, SwipeableDrawer } from '@mui/material';

// Our Components
import ClosedMobileNav from 'components/Frame/ClosedMobileNav';
import SoraSkeleton from 'components/Frame/LoadingSkelton';
import MobileNavMenu from 'components/NavMenu/MobileNavMenu';

// Our Hooks
import useGetProfile from 'hooks/useGetProfile';

// formerly Nav
function OpenMobileNav({ isMenuOpen, toggleMenuOpen }) {
	const { isLoading, isSuccess } = useGetProfile();

	if (isLoading) {
		return <SoraSkeleton />;
	}

	if (isSuccess) {
		return (
			<SwipeableDrawer
				open={isMenuOpen}
				onOpen={toggleMenuOpen}
				transitionDuration={350}
				onClose={toggleMenuOpen}
				PaperProps={{
					sx: {
						background:
							'linear-gradient(180deg, #B0CDE4 0%, #FFF 100%)',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						width: '100%',
						paddingLeft: 0
					}
				}}
			>
				<Box>
					<ClosedMobileNav toggleMenuOpen={toggleMenuOpen} />
				</Box>

				<Box>
					<MobileNavMenu />
				</Box>
			</SwipeableDrawer>
		);
	}
}

export default OpenMobileNav;
