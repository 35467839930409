import { useMemo, useState } from 'react';
import { Grid, Skeleton } from '@mui/material';

// Our Components
import Alert from 'components/Alert/index';
import TasksAccordion from 'components/Tasks/TasksAccordion';
import LoansTaskTable from 'components/Tasks/LoanTasksTable';

// Our Hooks
import useGetAdminLoanRequests from 'hooks/newLoanInquiries/useGetAdminLoanRequests';
import useMutateAddClientInvitation from 'hooks/useMutateAddClientInvitation';
import useMutateReinviteClient from 'hooks/tasks/useMutateReinviteClient';
import useMutateStartOnboardingProcess from 'hooks/Onboarding/AutomaticOnboarding/useMutateStartOnboardingProcess';

// Constants
import { COMPLETED, REJECTED, CANCELLED } from 'shared/constants';

const INACTIVE_STATUSES = new Set([COMPLETED, CANCELLED, REJECTED]);

function LoanTasks() {
	// Mutations
	const sendClientInvitation = useMutateAddClientInvitation();
	const sendClientReInvitation = useMutateReinviteClient();
	const startOnboardingProcess = useMutateStartOnboardingProcess();

	const { isError: isOnboardError, isSuccess: isOnboardSuccess } =
		startOnboardingProcess;

	const [clientSelected, setClientSelected] = useState(null);

	// GET call on page load
	// Pulling in the Advisor's in progress loans to populate tables
	const {
		isLoading,
		isSuccess,
		data: advisorsLoanRequests
	} = useGetAdminLoanRequests();

	const handleSelected = (value, clientData) => {
		setClientSelected(clientData);
	};

	const previousLoanRequests = useMemo(() => {
		if (isSuccess)
			return advisorsLoanRequests.filter(({ status }) =>
				INACTIVE_STATUSES.has(status)
			);
		return [];
	}, [advisorsLoanRequests, isSuccess]);

	const activeLoanRequests = useMemo(() => {
		if (isSuccess)
			return advisorsLoanRequests.filter(
				({ status }) => !INACTIVE_STATUSES.has(status)
			);
		return [];
	}, [advisorsLoanRequests, isSuccess]);

	const isSuccessAlert =
		sendClientInvitation.isSuccess ||
		sendClientReInvitation.isSuccess ||
		isOnboardSuccess;

	const isErrorAlert =
		sendClientInvitation.isError ||
		sendClientReInvitation.isError ||
		isOnboardError;

	return (
		<>
			{isSuccessAlert && <Alert variant="success" />}

			{isErrorAlert && <Alert variant="error" />}

			<Grid item xs={12}>
				<TasksAccordion
					defaultExpandState
					taskHeading="Active Loan Requests"
				>
					{isLoading && (
						<Skeleton
							sx={{ width: '100%', height: 400 }}
							variant="rectangular"
						/>
					)}

					{/* Active Loan Requests */}
					{isSuccess && (
						<LoansTaskTable
							taskList={activeLoanRequests}
							handleSelected={handleSelected}
							noLoanTasks={
								advisorsLoanRequests?.length < 1 ?? true
							}
						/>
					)}
				</TasksAccordion>
			</Grid>

			<Grid item xs={12}>
				<TasksAccordion
					defaultExpandState
					taskHeading="Previous Loan Requests"
				>
					{isLoading && (
						<Skeleton
							sx={{ width: '100%', height: 400 }}
							variant="rectangular"
						/>
					)}

					{/* Requested Loans */}
					{isSuccess && (
						<LoansTaskTable
							taskList={previousLoanRequests}
							handleSelected={handleSelected}
						/>
					)}
				</TasksAccordion>
			</Grid>
		</>
	);
}

export default LoanTasks;
