const parseLoanTerm = (term) => {
	if (term === '' || !term) return;

	const separated = term.split(' ');

	const [year, _, type] = separated;

	const fallBack = type !== 'Fixed' && type !== 'Variable' ? '' : type;

	return {
		year: parseInt(year),
		type: fallBack
	};
};

export default parseLoanTerm;
