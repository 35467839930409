// The Following Loan Inquiry States will disable the add loan offer button
import {
	ADMIN_COMPLETED,
	ADMIN_CANCELLED,
	ADMIN_REJECTED
} from 'shared/constants/LoanInquiry/LoanInquirySteps';

export const LOAN_INQUIRY_DISABLED_ADD_LOAN_OFFER = [
	ADMIN_COMPLETED,
	ADMIN_CANCELLED,
	ADMIN_REJECTED
];
