import { Box } from '@mui/material';

// Our Prop Types
import CommonProps from 'shared/prop-types';

function PDFSection({ children, sx }) {
	return (
		<Box
			sx={{
				width: '100%',
				marginBottom: 2,
				...sx
			}}
		>
			{children}
		</Box>
	);
}

PDFSection.propTypes = {
	sx: CommonProps.sx
};

PDFSection.defaultProps = {
	sx: {}
};

export default PDFSection;
