import { v4 as uuidv4 } from 'uuid';
import { Stepper, Step, StepConnector, StepLabel } from '@mui/material';

// Our Constants
import {
	ADMIN_STATUS_MAPPING,
	COMPLETED,
	DISABLED_LOAN_REQUEST_STEPS,
	LOAN_REQUEST_STEPS
} from 'shared/constants';

const isStepDisabled = (LoanRequestStatusName) =>
	DISABLED_LOAN_REQUEST_STEPS.includes(LoanRequestStatusName);

function LoanRequestStatusBar({ mobileView, status }) {
	const isHidden = isStepDisabled(status);

	if (isHidden) return null;

	const activeStep =
		status === COMPLETED
			? 10
			: LOAN_REQUEST_STEPS.findIndex((step) => {
					return step === ADMIN_STATUS_MAPPING[status];
				});

	return (
		<Stepper
			activeStep={activeStep}
			alternativeLabel
			sx={{
				marginTop: 4,
				marginBottom: 4,
				'&.MuiStepper-root.MuiStepper-vertical.MuiStepper-alternativeLabel':
					{ alignItems: 'center' }
			}}
			orientation={mobileView ? 'vertical' : 'horizontal'}
			connector={!mobileView ? <StepConnector /> : ''}
		>
			{LOAN_REQUEST_STEPS.map((label) => (
				<Step key={uuidv4()}>
					<StepLabel complete="true">{label}</StepLabel>
				</Step>
			))}
		</Stepper>
	);
}

export default LoanRequestStatusBar;
