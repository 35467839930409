import { useCallback } from 'react';
import { Navigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { Session } from '@talkjs/react';
import Talk from 'talkjs';

// Our Components
import Loader from 'components/Loader';

// Our Hooks
import useGetProfile from 'hooks/useGetProfile';

// Our Query Keys
import { TALKJS_ALL_UNREAD_MESSAGES } from 'shared/query-keys';

// Our Utils
import countUnreadMessages from 'shared/utils/talkjs/countUnreadMessages';

// Our Constants
import { ADMIN } from 'shared/constants';

const TALKJS_APP_ID = process.env.REACT_APP_TALKJS_APP_ID;

// Expected
function ProtectedRoute({ element }) {
	const queryClient = useQueryClient();
	const { isSuccess, isError, isLoading, data } = useGetProfile();

	const syncUser = useCallback(() => {
		if (isSuccess) {
			const { adminTalkJsId } = data;

			return new Talk.User({
				id: adminTalkJsId,
				name: 'Sora',
				email: ['contact@sorafinance.com'],
				role: ADMIN
			});
		}

		return 'sample_user_sebastion';
	}, [data, isSuccess]);

	if (isLoading) return <Loader />;

	if (isError) {
		return <Navigate to="/login" />;
	}

	if (isSuccess) {
		// talkjs code here
		return (
			<Session
				appId={TALKJS_APP_ID}
				syncUser={syncUser}
				onUnreadsChange={(conversations) => {
					const unreadMessageCount =
						countUnreadMessages(conversations);

					queryClient.setQueryData(
						TALKJS_ALL_UNREAD_MESSAGES,
						unreadMessageCount
					);
				}}
			>
				{element}
			</Session>
		);
	}

	return element;
}

export default ProtectedRoute;
