import { Grid } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useTheme } from '@emotion/react';

// Our Components
import BasicFrame from 'components/Frame/Basic';
import LoginForm from 'components/LoginForm/LoginForm';
import LoginArtAndSoraLogo from 'components/LoginForm/LoginArtAndSoraLogo';

export default function LoginPage() {
	const SoraTheme = useTheme();

	return (
		<>
			<Helmet>
				<title>Login</title>
			</Helmet>

			<BasicFrame sx={{ height: '100vh', width: '100%' }}>
				<Grid
					item
					xs={6}
					sx={{
						height: '100%',
						width: '100%'
					}}
				>
					<LoginArtAndSoraLogo />
				</Grid>
				<Grid
					item
					xs={1}
					sx={{
						backgroundColor: SoraTheme.palette.primary.white
					}}
				/>
				<Grid
					item
					xs={4}
					sx={{
						backgroundColor: SoraTheme.palette.primary.white
					}}
				>
					<LoginForm />
				</Grid>
			</BasicFrame>
		</>
	);
}
