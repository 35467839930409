import { useState, useCallback, useMemo } from 'react';
import {
	Box,
	FormHelperText,
	InputAdornment,
	OutlinedInput,
	TextField,
	Typography
} from '@mui/material';
import numbro from 'numbro';
import { useTheme } from '@emotion/react';

// Our Components
import PropTypes from 'prop-types';
import CommonPropTypes from 'shared/prop-types';
import { TEXT } from 'components/Input/Types';

// helper function that makes sure the incoming value from onChange is a string
// makes sure that only numbers are accepted
// makes sure to play well with decimals
// makes sure to format the value so it has commas in the correct place
function getFormattedValue(incomingValue) {
	const valueConvertedToString = `${incomingValue}`;
	if (valueConvertedToString === '') return valueConvertedToString;
	if (valueConvertedToString.includes('.')) {
		const indexOfPeriod = valueConvertedToString.indexOf('.');
		const numbersAfterthePeriod = valueConvertedToString.slice(
			indexOfPeriod + 1
		);
		const hasLessThanTwoDigitsAfterDecimal =
			numbersAfterthePeriod.length < 2;

		if (hasLessThanTwoDigitsAfterDecimal) {
			const formmatedNumberBeforePeriod = numbro(
				+valueConvertedToString
			).format({
				thousandSeparated: true
			});

			const isDigitAfterPeriodNotZero = +numbersAfterthePeriod > 0;

			if (isDigitAfterPeriodNotZero) {
				return numbro(+valueConvertedToString).format({
					thousandSeparated: true,
					mantissa: 1
				});
			}
			return `${formmatedNumberBeforePeriod}.${numbersAfterthePeriod}`;
		}
		const hasExactlyTwoDigitsAfterDecimal =
			numbersAfterthePeriod.length === 2;

		if (hasExactlyTwoDigitsAfterDecimal) return valueConvertedToString;
		return numbro(+valueConvertedToString).format({
			thousandSeparated: true,
			mantissa: 2
		});
	}

	return numbro(+valueConvertedToString).format({
		thousandSeparated: true
	});
}

function CurrencyTextInput({
	disabled,
	error,
	helperText,
	inputProps,
	inputStyle,
	label,
	onBlur,
	onChange,
	placeholder,
	required,
	subLabel,
	sx,
	tabIndex,
	value,
	dependingValues
}) {
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;

	const requiredAwareLabel = useMemo(
		() => (required ? `${label} *` : label),
		[required]
	);

	const [wasChecked, setWasChecked] = useState(false);

	// helper function that will set its "real value" and keeps it a STRING
	// this means that value coming in => number as string
	// value coming out => number as string
	const onChangeHandler = useCallback((e) => {
		const incomingValue = e.target.value;

		if (incomingValue === '') {
			onChange('');
			return;
		}

		const incomingValueWithoutCommas = incomingValue.replaceAll(',', '');

		if (incomingValueWithoutCommas.includes('.')) {
			const indexOfPeriod = incomingValueWithoutCommas.indexOf('.');
			const numbersAfterthePeriod = incomingValueWithoutCommas.slice(
				indexOfPeriod + 1
			);
			const hasMoreThanTwoDecimals = numbersAfterthePeriod.length > 2;
			if (hasMoreThanTwoDecimals) return;
		}

		// eslint-disable-next-line no-restricted-globals
		if (isNaN(incomingValueWithoutCommas)) return;
		onChange(incomingValueWithoutCommas); // This is where the value changes
	}, dependingValues);

	// ensures it remains a string
	const formatedValue = getFormattedValue(value);
	return (
		<Box sx={sx}>
			{label && (
				<Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
					{requiredAwareLabel}
				</Typography>
			)}

			{/* inputStyle defaults to OutlineInput which is our standard */}
			{inputStyle === 'default' ? (
				<OutlinedInput
					// This check is if its visible return the type passed in props
					// Otherwise set the type to password that way the toggle behaves as intended
					disabled={disabled}
					type={TEXT}
					error={wasChecked && error}
					placeholder={placeholder}
					value={formatedValue}
					onBlur={() => {
						if (wasChecked === false) setWasChecked(true);
						onBlur();
					}}
					onChange={(e) => onChangeHandler(e)}
					startAdornment={
						<InputAdornment position="start">$</InputAdornment>
					}
					fullWidth
					required={required}
					tabIndex={tabIndex}
					inputProps={inputProps}
				/>
			) : (
				<TextField
					disabled={disabled}
					variant="standard"
					type={TEXT}
					helperText="Price Here"
					value={formatedValue}
					onChange={(e) => onChangeHandler(e)}
					sx={{
						'& input[type=number]': {
							MozAppearance: 'textfield'
						},
						'& input[type=number]::-webkit-outer-spin-button': {
							WebkitAppearance: 'none',
							margin: 0
						},
						'& input[type=number]::-webkit-inner-spin-button': {
							WebkitAppearance: 'none',
							margin: 0
						}
					}}
					FormHelperTextProps={{
						sx: { color: primary.black, textAlign: 'center' }
					}}
				/>
			)}
			{/* Below may be complex but if checked. There is an error. And we provided helper test display it! */}
			{!subLabel && error && wasChecked && helperText && (
				<FormHelperText
					variant="standard"
					sx={{
						marginLeft: 2
					}}
				>
					{helperText}
				</FormHelperText>
			)}
			{/* TODO @Daniel figure out a way to rework this component to support errorText and helperText so they're separate */}
			{subLabel && (
				<FormHelperText
					variant="standard"
					sx={{
						marginLeft: 2
					}}
				>
					{subLabel}
				</FormHelperText>
			)}
		</Box>
	);
}

CurrencyTextInput.propTypes = {
	disabled: PropTypes.bool,
	error: PropTypes.bool,
	dependingValues: PropTypes.array,
	inputStyle: PropTypes.oneOf(['default', 'underline']),
	sx: CommonPropTypes.sx,
	label: PropTypes.string,
	helperText: PropTypes.string,
	placeholder: PropTypes.string,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	required: PropTypes.bool
};

CurrencyTextInput.defaultProps = {
	disabled: false,
	error: false,
	dependingValues: [],
	inputStyle: 'default',
	sx: { height: 120 },
	label: undefined,
	helperText: undefined,
	placeholder: undefined,
	onChange: () => {},
	onBlur: () => undefined,
	required: false
};

export default CurrencyTextInput;
