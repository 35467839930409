import { useMemo } from 'react';

// Our Components
import RequestDetailsItem from 'components/LoanRequestDetails/RequestDetails/RequestDetailsItem';

// Our Utils
import { dollarFormatter } from 'shared/utils';

function AutoSpecificRequestDetails({
	isLoanOfferAccepted,
	isNewAutoLoan,
	currentRemainingTerm,
	estimatedPurchaseValue,
	loanTenure,
	vehicleMileage
}) {
	const formattedEstimatedPurchaseValue = useMemo(
		() => dollarFormatter.format(estimatedPurchaseValue ?? 0),
		[estimatedPurchaseValue]
	);

	if (isNewAutoLoan) {
		const isSafeLoanTenure = loanTenure && loanTenure !== '';
		return (
			<>
				<RequestDetailsItem
					heading="Vehicle purchase value"
					value={formattedEstimatedPurchaseValue}
				/>
				{isSafeLoanTenure && (
					<RequestDetailsItem
						heading="Loan Term"
						value={`${loanTenure} years`}
					/>
				)}
			</>
		);
	}

	if (isLoanOfferAccepted) {
		// Refinance Auto Loan
		return (
			<>
				<RequestDetailsItem
					heading="Vehicle Mileage"
					value={vehicleMileage}
				/>
			</>
		);
	}

	// Refi Auto Loan Guaranteed

	return (
		<>
			<RequestDetailsItem
				heading="Current Remaining Term"
				value={`${currentRemainingTerm ?? 'NA'} years`}
			/>
		</>
	);
}

export default AutoSpecificRequestDetails;
