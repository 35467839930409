import PropTypes from 'prop-types';

// This Prop Type expresses client data used in the LoanRequestDetailsHeading component.
const CLIENT_DATA_PROP_TYPE = PropTypes.shape({
	annualIncome: PropTypes.oneOfType([PropTypes.number]),
	clientAddress: PropTypes.string,
	clientName: PropTypes.string,
	creditScore: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	firstName: PropTypes.string,
	lastName: PropTypes.string,
	stateLocation: PropTypes.string
});

export default CLIENT_DATA_PROP_TYPE;
