import { FormGroup, FormControlLabel, Switch } from '@mui/material';

function BasicSwitch({ label, checked, onChange }) {
	return (
		<FormGroup>
			<FormControlLabel
				sx={{ textAlign: 'center' }}
				value="top"
				labelPlacement="top"
				control={<Switch checked={checked} onChange={onChange} />}
				label={label}
			/>
		</FormGroup>
	);
}

export default BasicSwitch;
