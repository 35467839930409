import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

// Our components
import AddLoanOfferForm from 'components/Forms/AdminLoanOfferForms/AddLoanOfferForm';
import Loader from 'components/Loader/index';
import Modal from 'components/Modal/Modal';
import { SecondaryButton, TertiaryButton } from 'components/Button/Button';

// Our Hooks
import useMutateDeleteLoanOffer from 'hooks/newLoanInquiries/loanOffer/useMutateDeleteLoanOffer';

// Our Utils
import { dollarFormatter } from 'shared/utils';

function AdminLoanInquiryModal({
	loanType,
	selectedLoanOfferData,
	isOpen,
	setIsModalOpen,
	variant,
	sx,
	tradelineType
}) {
	const isEdit = variant === 'edit';
	const isDelete = variant === 'delete';
	const isView = variant === 'view';

	const closeModal = useCallback(() => setIsModalOpen(false), []);

	// Delete Hook
	const deleteLoanOffer = useMutateDeleteLoanOffer(closeModal);

	// Is Edit means the edit loan offer modal.
	if (isEdit)
		return (
			<Modal
				TitleComponent={
					<Box
						sx={{
							display: 'flex',
							alignItems: 'flex-end',
							gap: 2,
							marginBottom: 2
						}}
					>
						<Typography variant="h2Gascogne">
							Edit Loan Offer
						</Typography>
						<Typography
							variant="subtitle1"
							sx={{ color: 'black', paddingBottom: 0.5 }}
						>
							<strong>Source:</strong>
						</Typography>
						<Typography
							variant="body1"
							sx={{ color: 'black', paddingBottom: 0.5 }}
						>
							Sora Admin
						</Typography>
					</Box>
				}
				isOpen={isOpen}
				handleClose={closeModal}
				sx={sx}
			>
				<AddLoanOfferForm
					tradeLineType={tradelineType}
					variant="edit"
					selectedLoanOfferData={selectedLoanOfferData}
					loanType={loanType}
					postSubmitHandler={closeModal}
				/>
			</Modal>
		);

	if (isDelete) {
		return (
			<Modal
				TitleComponent={
					<Typography variant="h2Gascogne" sx={{ color: 'black' }}>
						Are you sure you want to delete this <br />
						loan offer?
					</Typography>
				}
				isOpen={isOpen}
				handleClose={closeModal}
				sx={sx}
			>
				<Box sx={{ display: 'flex', gap: 2 }}>
					{deleteLoanOffer.isLoading ? (
						<Loader />
					) : (
						<TertiaryButton
							onClick={() =>
								deleteLoanOffer.mutate(
									selectedLoanOfferData.loanOfferId
								)
							}
						>
							Delete
						</TertiaryButton>
					)}

					<SecondaryButton onClick={closeModal}>
						Cancel
					</SecondaryButton>
				</Box>
			</Modal>
		);
	}

	if (isView && selectedLoanOfferData) {
		const {
			lender,
			loanAmount,
			loanTerm,
			rate,
			upfrontCost,
			monthlyPayment,
			feeType,
			soraFeeAmount
		} = selectedLoanOfferData;

		const loanOfferDetails = [
			{ heading: 'Loan Type', text: loanType },
			{ heading: 'Lender', text: lender },
			{ heading: 'Loan Term', text: loanTerm },
			{
				heading: 'Loan Amount',
				text: dollarFormatter.format(loanAmount ?? 0)
			},
			{
				heading: 'Monthly Payment',
				text: dollarFormatter.format(monthlyPayment ?? 0)
			},
			{ heading: 'APR', text: `${rate ?? 0}%` },
			{
				heading: 'One Time Fees',
				text: dollarFormatter.format(upfrontCost ?? 0)
			},
			{ heading: 'Fee Type', text: feeType },
			{
				heading: 'Sora Fee',
				text: dollarFormatter.format(soraFeeAmount)
			}
		];
		return (
			<Modal
				title="Loan Offer Details"
				isOpen={isOpen}
				handleClose={closeModal}
				sx={sx}
			>
				<Grid container spacing={2}>
					{loanOfferDetails.map(({ heading, text }) => (
						<Grid item xs={4} key={uuidv4()} sx={{ marginTop: 2 }}>
							<Typography
								variant="subtitle1"
								sx={{ color: 'black', marginBottom: 0.5 }}
							>
								{heading}
							</Typography>
							<Typography variant="body1" sx={{ color: 'black' }}>
								{text ?? 'NA'}
							</Typography>
						</Grid>
					))}
				</Grid>
			</Modal>
		);
	}
	// For now this just means we are in Add mode.
	return (
		<Modal
			TitleComponent={
				<Box
					sx={{
						display: 'flex',
						alignItems: 'flex-end',
						gap: 2,
						marginBottom: 2
					}}
				>
					<Typography variant="h2Gascogne">Add Loan Offer</Typography>
					<Typography
						variant="subtitle1"
						sx={{ color: 'black', paddingBottom: 0.5 }}
					>
						<strong>Source:</strong>
					</Typography>
					<Typography
						variant="body1"
						sx={{ color: 'black', paddingBottom: 0.5 }}
					>
						Sora Admin
					</Typography>
				</Box>
			}
			isOpen={isOpen}
			handleClose={closeModal}
			sx={sx}
		>
			<AddLoanOfferForm
				tradeLineType={tradelineType}
				loanType={loanType}
				postSubmitHandler={closeModal}
			/>
		</Modal>
	);
}

AdminLoanInquiryModal.propTypes = {
	variant: PropTypes.oneOf(['add', 'delete', 'edit', 'view']),
	selectedLoanOfferData: PropTypes.shape({
		id: PropTypes.string,
		clientId: PropTypes.number,
		newLoanId: PropTypes.number,
		lender: PropTypes.string,
		loanType: PropTypes.string,
		rate: PropTypes.number,
		upfrontCost: PropTypes.number,
		monthlyPayment: PropTypes.number,
		lastReviewed: PropTypes.string,
		feeType: PropTypes.string,
		soraFeeAmount: PropTypes.number,
		activationDate: PropTypes.string,
		expirationDate: PropTypes.string,
		offeredBy: PropTypes.string
	}),
	isOpen: PropTypes.bool.isRequired,
	setIsModalOpen: PropTypes.func.isRequired
};

AdminLoanInquiryModal.defaultProps = {
	variant: 'add'
};

export default AdminLoanInquiryModal;
