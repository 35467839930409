import { Chip, Grid } from '@mui/material';
import { useTheme } from '@emotion/react';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';

// Our Components
import ColumnItem from 'components/DataTable/ColumnItem';
import DataTable from 'components/DataTable/DataTable';

// Our Utils
import { dataIsValid, dollarFormatter } from 'shared/utils';
import formatDate from 'shared/utils/date/formatDate';

// Routes
import { LOAN_INQUIRY_DETAILS_ROUTE } from 'routes';

// Our constants
import {
	ADMIN_STATUS_TABLE_MAPPING,
	NEW_LOAN_OFFER_TYPE,
	REFINANCE_OFFER_TYPE,
	CASHOUT_OFFER_TYPE,
	AUTO,
	FARM,
	LOT,
	MORTGAGE,
	PERSONAL,
	BUSINESS,
	STUDENT,
	CONSTRUCTION,
	HELOC,
	REVERSEMORTGAGE
} from 'shared/constants';

const formatOfferType = (offerType, tradelineType) => {
	// Turn this if statement into a hash map with the offerType as the key

	if (offerType === NEW_LOAN_OFFER_TYPE) {
		if (tradelineType === AUTO) {
			return 'New Auto Loan';
		}
		if (tradelineType === CONSTRUCTION) {
			return 'Construction Loan';
		}
		if (tradelineType === FARM) {
			return 'Farm Loan';
		}
		if (tradelineType === LOT) {
			return 'Lot Loan';
		}
		if (tradelineType === MORTGAGE) {
			return 'New Mortgage Loan';
		}
		if (tradelineType === PERSONAL) {
			return 'New Personal Loan';
		}
		if (tradelineType === BUSINESS) {
			return 'Small Business Loan';
		}
		if (tradelineType === 'BUSINESS') {
			return 'Small Business Loan';
		}
	}
	if (offerType === REFINANCE_OFFER_TYPE) {
		if (tradelineType === AUTO) {
			return 'Auto Loan Refinance';
		}
		if (tradelineType === HELOC) {
			return 'HELOC Loan';
		}
		if (tradelineType === MORTGAGE) {
			return 'Mortgage Loan Refinance';
		}
		if (tradelineType === PERSONAL) {
			return 'Personal Loan Refinance';
		}
		if (tradelineType === REVERSEMORTGAGE) {
			return 'Reverse Mortgage Loan';
		}
		if (tradelineType === STUDENT) {
			return 'Student Loan Refinance';
		}
	}
	if (offerType === CASHOUT_OFFER_TYPE && tradelineType === MORTGAGE) {
		return 'Cash Out Refinance';
	}
	return 'Unknown Loan Type';
};

function LoanTasksTable({ taskList, withOnboardNowOverlay, noLoanTasks }) {
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;

	const navigate = useNavigate();

	const mutatedRowData = taskList.map((loanInquiryData) => {
		const {
			id,
			advisorName,
			adminStatusText,
			clientId,
			createdDate,
			downPaymentPercent,
			firstName,
			lastName,
			lastUpdated,
			loanAmount,
			offerType,
			status,
			taskDone,
			tradelineType
		} = loanInquiryData;

		const isNameInvalid = !dataIsValid(firstName) || !dataIsValid(lastName);

		const checkForEmptyFirstName =
			firstName?.length < 1 ? 'Unknown' : firstName;
		const checkForEmptyLastName =
			lastName?.length < 1 ? 'Unknown' : lastName;

		const clientFullName = isNameInvalid
			? 'Unknown'
			: `${checkForEmptyFirstName} ${checkForEmptyLastName}`;

		const formattedLoanType = formatOfferType(offerType, tradelineType);

		return {
			id: uuidv4(),
			advisorName,
			adminStatusText,
			clientName: clientFullName,
			loanType: formattedLoanType,
			loanAmount,
			APR: downPaymentPercent,
			dateRequested: createdDate,
			loanRequestId: id,
			clientId,
			done: taskDone,
			lastUpdated,
			status
		};
	});

	// data Keys
	const loanRequestId = 'loanRequestId';
	const advisorName = 'advisorName';
	const clientName = 'clientName';
	const loanType = 'loanType';
	const loanAmount = 'loanAmount';
	const dateRequested = 'dateRequested';
	const status = 'status';

	const tableHeadings = [
		ColumnItem(loanRequestId, 'ID #', 1),
		ColumnItem(advisorName, 'Advisor Name', 2),
		ColumnItem(clientName, 'Client Name', 2),
		ColumnItem(loanType, 'Loan Type', 2),
		ColumnItem(loanAmount, 'Loan Amount', 2, {
			valueFormatter: (params) => {
				const { value } = params;

				const isLoanAmountMissing = !dataIsValid(value);

				if (isLoanAmountMissing) return 'NA';

				const formattedDollar = dollarFormatter.format(value);

				return formattedDollar;
			}
		}),
		ColumnItem(dateRequested, 'Date Requested', 2, {
			valueFormatter: (params) => {
				const { value } = params;
				const formattedDate = formatDate(value);
				return formattedDate;
			}
		}),
		ColumnItem(status, 'Status', 2, {
			renderCell: (params) => {
				const { value, row } = params;

				const statusConfig = ADMIN_STATUS_TABLE_MAPPING[value];

				const bgcolor = statusConfig?.bgcolor;
				const color = statusConfig?.color;

				const text =
					row.adminStatusText === 'REJECTED'
						? 'Rejected'
						: row.adminStatusText;

				const themeTextColor = primary[color] ?? 'black';
				const themeBackgroundColor = primary[bgcolor] ?? 'lightBlue';
				return (
					<Chip
						label={text}
						sx={{
							color: themeTextColor,
							bgcolor: themeBackgroundColor,
							paddingLeft: 1,
							paddingRight: 1,
							width: '100%',
							maxWidth: 200
						}}
					/>
				);
			}
		})
	];

	return (
		<Grid item xs={12}>
			<DataTable
				enableToolbar
				columns={tableHeadings}
				rows={mutatedRowData}
				disableSelectionOnClick
				handleRowClick={(rowData) => {
					const { loanRequestId } = rowData;
					navigate(
						`${LOAN_INQUIRY_DETAILS_ROUTE}?loanRequestId=${loanRequestId}`
					);
				}}
				rowHeight={75}
				height={550}
				pageSize={5}
				rowsPerPageOptions={5}
				withOnboardNowOverlay={withOnboardNowOverlay ?? false}
				noLoanTasks={noLoanTasks ?? false}
				defaultSortModel={[
					{
						field: dateRequested,
						sort: 'desc'
					}
				]}
			/>
		</Grid>
	);
}

export default LoanTasksTable;
