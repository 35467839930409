import { useMutation, useQueryClient } from 'react-query';

// Our Axios Instance
import axiosInstance from 'services/API/API';

// Our endpoint
import { updateLoanDocumentStatusEndpoint } from 'shared/api-urls';

// Our Query keys
import {
	LOAN_REQUEST_RELATED_DOCUMENTS,
	LOAN_REQUEST
} from 'shared/query-keys';

function useMutateUpdateDocumentStatus(handleClose) {
	const queryClient = useQueryClient();
	return useMutation(
		async (payload) => {
			const response = await axiosInstance.patch(
				updateLoanDocumentStatusEndpoint,
				payload
			);

			return response.data;
		},
		{
			onSuccess: (loanRequestId) => {
				queryClient.invalidateQueries(
					LOAN_REQUEST_RELATED_DOCUMENTS,
					loanRequestId
				);

				queryClient.invalidateQueries(LOAN_REQUEST, loanRequestId);
			}
		}
	);
}

export default useMutateUpdateDocumentStatus;
