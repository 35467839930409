const selectLoanOfferDetails = (state) => {
	const { newLoanDetails, newLoanOffers } = state;

	// Loan Offer Specific Details
	const { acceptedOfferId } = newLoanDetails;

	const selectedOffer = newLoanOffers.find(
		(currentOffer) => currentOffer.id === acceptedOfferId
	);

	return selectedOffer;
};

export default selectLoanOfferDetails;
