import { v4 as uuidv4 } from 'uuid';
import axiosInstance from 'services/API/API';
import { useQuery } from 'react-query';
import { getLoanRequestRelatedDocuments } from 'shared/api-urls';

// Query Key
import { LOAN_REQUEST_RELATED_DOCUMENTS } from 'shared/query-keys';

function useGetLoanRequestRelatedDocuments(loanRequestId) {
	return useQuery(
		[LOAN_REQUEST_RELATED_DOCUMENTS, loanRequestId],
		async () => {
			const res = await axiosInstance.get(
				`${getLoanRequestRelatedDocuments}/${loanRequestId}`
			);
			return res.data.data;
		},
		{
			refetchOnWindowFocus: false,
			select: (documentList) => {
				if (documentList) {
					return documentList.map((eachDocument) => ({
						...eachDocument,
						id: uuidv4()
					}));
				}
				return [];
			}
		}
	);
}

export default useGetLoanRequestRelatedDocuments;
