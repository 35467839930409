// Valid TradeLine Types
export const ALL = 'ALL';
export const AUTO = 'AUTO';
export const BUSINESS = 'BUSINESS';
export const COLLECTION = 'COLLECTION';
export const CONSTRUCTION = 'CONSTRUCTION';
export const CREDITCARD = 'CREDITCARD';
export const COMMERCIAL = 'COMMERCIAL';
export const FARM = 'FARM';
export const HELOC = 'HELOC';
export const LOAN = 'LOAN';
export const LOT = 'LOT';
export const MORTGAGE = 'MORTGAGE';
export const PERSONAL = 'PERSONAL';
export const STUDENT = 'STUDENT';
export const CASHOUT = 'CASHOUT';
export const REVERSEMORTGAGE = 'REVERSE_MORTGAGE';

// Valid Offer Types
export const NEW_LOAN_OFFER_TYPE = 'NEW_LOAN';
export const REFINANCE_OFFER_TYPE = 'REFINANCE';
export const CASHOUT_OFFER_TYPE = 'CASHOUT';

// translated TradeLine Type used for display
export const MORTGAGE_TAB_TEXT = 'Home';

// Valid Onboard Update States
export const ASSETS_UPDATED = 'ASSETS_UPDATED';
export const INCOME_ACCOUNT_CONNECTED = 'INCOME_ACCOUNT_CONNECTED';
export const INCOME_UPDATED = 'INCOME_UPDATED';
export const LIABILITIES_UPDATED = 'LIABILITIES_UPDATED';
export const LIABILITY_ACCOUNT_CONNECTED = 'LIABILITY_ACCOUNT_CONNECTED';
export const REVIEW_COMPLETED = 'REVIEW_COMPLETED';
export const SIGNUP_COMPLETED = 'SIGNUP_COMPLETED';
export const TASKS_IN_PROGRESS_FLAGS = [
	SIGNUP_COMPLETED,
	LIABILITY_ACCOUNT_CONNECTED,
	LIABILITIES_UPDATED,
	INCOME_ACCOUNT_CONNECTED,
	INCOME_UPDATED,
	ASSETS_UPDATED
];

export const REQ_IN_PROCESS = 'REQ_IN_PROCESS';
export const SEARCH_RATES = 'SEARCH_RATES';
export const ADD_RATES = 'ADD_RATES';
export const RATE_SELECTED = 'RATE_SELECTED';
export const APPROVE_REQ = 'APPROVE_REQ';
export const CONTACT_LENDER = 'CONTACT_LENDER';
export const IN_UNDERWRITING = 'IN_UNDERWRITING';
export const PENDING_DISBUR = 'PENDING_DISBUR';
export const COMPLETED = 'ADMIN_COMPLETED';
export const CANCELLED = 'ADMIN_CANCELLED';
export const REJECTED = 'ADMIN_REJECTED';

export const MORTGAGE_LOAN_TENURE_OPTIONS = [
	'10 Year Fixed',
	'15 Year Fixed',
	'30 Year Fixed',
	'3 ARM',
	'5 ARM',
	'7 ARM',
	'10 ARM'
];

export const ADMIN_STATUSES_LIST = [
	REQ_IN_PROCESS,
	SEARCH_RATES,
	ADD_RATES,
	RATE_SELECTED,
	APPROVE_REQ,
	CONTACT_LENDER,
	IN_UNDERWRITING,
	PENDING_DISBUR,
	COMPLETED,
	CANCELLED,
	REJECTED
];

export const ADMIN_STATUS_TABLE_MAPPING = {
	REQ_IN_PROCESS: {
		bgcolor: 'orange',
		color: 'black'
	},
	SEARCH_RATES: {
		bgcolor: 'orange',
		color: 'black'
	},
	ADD_RATES: {
		bgcolor: 'orange',
		color: 'black'
	},
	RATE_SELECTED: {
		bgcolor: 'orange',
		color: 'black'
	},
	APPROVE_REQ: {
		bgcolor: 'orange',
		color: 'black'
	},
	CONTACT_LENDER: {
		bgcolor: 'orange',
		color: 'black'
	},
	IN_UNDERWRITING: {
		bgcolor: 'yellow',
		color: 'black'
	},
	PENDING_DISBUR: {
		bgcolor: 'yellow',
		color: 'black'
	},
	ADMIN_COMPLETED: {
		bgcolor: 'green',
		color: 'black'
	},
	ADMIN_CANCELLED: {
		bgcolor: 'grey',
		color: 'black'
	},
	ADMIN_REJECTED: {
		bgcolor: 'grey',
		color: 'black'
	}
};

export const ADVISOR_ADMIN_MAPPING = {
	REQ_IN_PROCESS: 'PENDING_INFO',
	SEARCH_RATES: 'FA_SEARCH_RATES',
	ADD_RATES: 'FA_SEARCH_RATES',
	RATE_SELECTED: 'SELECT_RATE',
	APPROVE_REQ: 'UNDER_REVIEW',
	CONTACT_LENDER: 'UNDER_REVIEW',
	IN_UNDERWRITING: 'PROCESSING',
	PENDING_DISBUR: 'PROCESSING',
	ADMIN_COMPLETED: 'FA_COMPLETED',
	ADMIN_CANCELLED: 'FA_CANCELLED',
	ADMIN_REJECTED: 'FA_REJECTED',
	PENDING_INFO: 'REQ_IN_PROCESS',
	FA_SEARCH_RATES: 'SEARCH_RATES',
	// eslint-disable-next-line no-dupe-keys
	FA_SEARCH_RATES: 'ADD_RATES',
	SELECT_RATE: 'RATE_SELECTED',
	UNDER_REVIEW: 'APPROVE_REQ',
	// eslint-disable-next-line no-dupe-keys
	UNDER_REVIEW: 'CONTACT_LENDER',
	PROCESSING: 'IN_UNDERWRITING',
	// eslint-disable-next-line no-dupe-keys
	PROCESSING: 'PENDING_DISBUR',
	FA_COMPLETED: 'ADMIN_COMPLETED',
	FA_CANCELLED: 'ADMIN_CANCELLED',
	FA_REJECTED: 'ADMIN_REJECTED'
};

export const ADMIN_STATUS_MAPPING = {
	REQ_IN_PROCESS: 'Request in Process',
	SEARCH_RATES: 'Search Rates',
	ADD_RATES: 'Add Rates',
	RATE_SELECTED: 'Rate Selected',
	APPROVE_REQ: 'Approve Request',
	CONTACT_LENDER: 'Contact Lender',
	IN_UNDERWRITING: 'In Underwriting',
	PENDING_DISBUR: 'Pending Disbursement',
	ADMIN_COMPLETED: 'Completed',
	ADMIN_CANCELLED: 'Cancelled',
	ADMIN_REJECTED: 'Rejected',
	'Request in Process': 'REQ_IN_PROCESS',
	'Search Rates': 'SEARCH_RATES',
	'Add Rates': 'ADD_RATES',
	'Rate Selected': 'RATE_SELECTED',
	'Approve Request': 'APPROVE_REQ',
	'Contact Lender': 'CONTACT_LENDER',
	'In Underwriting': 'IN_UNDERWRITING',
	'Pending Disbursement': 'PENDING_DISBUR',
	Completed: 'ADMIN_COMPLETED',
	Cancelled: 'ADMIN_CANCELLED',
	Rejected: 'ADMIN_REJECTED'
};

export const INITIAL_REQUEST = 'Initial request';
export const ADD_ADDITIONAL_INFORMATION = 'Add additional information';
export const UNDER_REVIEW = 'Under review';

export const LOAN_REQUEST_STEPS = [
	'Request in Process',
	'Search Rates',
	'Add Rates',
	'Rate Selected',
	'Approve Request',
	'Contact Lender',
	'In Underwriting',
	'Pending Disbursement',
	'Completed'
];

export const DISABLED_LOAN_REQUEST_STEPS = [CANCELLED, REJECTED];

// Invite status
export const INVITED = 'INVITED';

// Task Table Default Options
export const DEFAULT_TASK_ACTION_OPTIONS = [
	'Complete onboard',
	'Invite to onboard'
];

// Loans Tasks page Table default options
export const LOAN_TASK_ACTION_OPTIONS = ['Contact Sora', 'Review Loan'];
// export const Unknown_Client_State = 'Unknown Client State' This is a possible item but it has not been clarified yet.

// Currently Accepted Offer Keys
export const ACCEPTED_OFFER_KEYS = [
	'closingCost',
	'interestRate',
	'monthlyPayUsd',
	'totalCashOutAmount',
	'totalCostOfDebt',
	'totalLoanAmount',
	'tenure',
	'lender',
	'soraScore',
	'cashOutPotential',
	'remainingMonth'
];

// App Constants
export const CREDIT_SCORE_OPTIONS = [
	{
		label: 'Excellent (720)+',
		value: 'EXCELLENT'
	},
	{
		label: 'Good (680-719)',
		value: 'GOOD'
	},
	{
		label: 'Fair (640-679)',
		value: 'FAIR'
	},
	{
		label: 'Poor (<639)',
		value: 'POOR'
	},
	{
		label: "I don't know",
		value: 'DONOTKNOW'
	}
];

export const CITIZEN_STATUS_OPTIONS = [
	'US Citizen',
	'Permanent Resident',
	'Non Citizen',
	'Other Citizenship'
];

export const EDUCATION_LEVEL_OPTIONS = [
	'Some High School',
	'High School Graduate',
	'Bachelors',
	'Masters',
	'Doctorate',
	'Professional',
	'Other Educational Degree'
];

export const EMPLOYMENT_STATUS_OPTIONS = [
	'Employed',
	'Unemployed',
	'Other Employment Status'
];

export const ENUM_FINANCIAL_GOAL_MAPPING = [
	{
		label: 'Reduce my monthly payments',
		value: 'LOW_EMI'
	},
	{
		label: 'Reduce total cost of debt',
		value: 'LOW_COD'
	},
	{
		label: 'Pay off my loans faster',
		value: 'EARLY_PAYOFF'
	},
	{
		label: 'Cash out',
		value: 'CASH_OUT'
	}
];

export const FINANCIAL_GOAL_OPTIONS = [
	'Reduce my monthly payments',
	'Reduce total cost of debt',
	'Pay off my loans faster',
	'Cash out'
];

export const LOAN_TENURE_OPTIONS = {
	Fixed: ['10-year', '15-year', '20-year', '30-year', '40-year'],
	ARM: ['3-year', '5-year', '7-year', '10-year']
};

export const MORTGAGE_LOAN_LENGTH_OPTIONS = [
	{
		label: '0-4 years',
		value: 'ZEROTOFOURYEARS'
	},
	{
		label: '5-7 years',
		value: 'FIVETOSEVENYEARS'
	},
	{
		label: '8-14 years',
		value: 'EIGHTTOFOURTEENYEARS'
	},
	{
		label: '15-29 years',
		value: 'FIFTEENTOTWENTYNINEYEARS'
	},
	{
		label: '30+ years',
		value: 'THIRTYPLUSYEARS'
	}
];

export const US_STATE_OPTIONS = [
	'AK',
	'AL',
	'AR',
	'AS',
	'AZ',
	'CA',
	'CO',
	'CT',
	'DC',
	'DE',
	'FL',
	'FM',
	'GA',
	'GU',
	'HI',
	'IA',
	'ID',
	'IL',
	'IN',
	'KS',
	'KY',
	'LA',
	'MA',
	'MD',
	'ME',
	'MH',
	'MI',
	'MN',
	'MO',
	'MP',
	'MS',
	'MT',
	'NC',
	'ND',
	'NE',
	'NH',
	'NJ',
	'NM',
	'NV',
	'NY',
	'OH',
	'OK',
	'OR',
	'PA',
	'PR',
	'PW',
	'RI',
	'SC',
	'SD',
	'TN',
	'TX',
	'UT',
	'VA',
	'VI',
	'VT',
	'WA',
	'WI',
	'WV',
	'WY'
];

export const VETERAN_STATUS_OPTIONS = ['Yes', 'No'];

export const TYPES_OF_HOME_PROPERTIES = [
	'Primary',
	'Secondary/Vacation',
	'Rental'
];

export const DEFAULT_DECIMAL_ROUND = 2;

// Liability Constants
export const PRE_POPULATE_FIELDS_TO_SKIP = ['rateManuallyUpdated'];
export const DEGREE_TYPES = [
	'Some High School',
	'High School Graduate',
	'Bachelors',
	'Masters',
	'Doctorate',
	'Professional',
	'Other Educational Degree'
];
export const TYPES_OF_STUDENT_LOANS = ['Federal', 'Private'];

// Rules Engine MODES
export const FE_CHATGPT_ONLY = '0';
export const BE_NO_CHATGPT = '1';
export const BE_WITH_CHATGPT = '2';

// Roles
export const ADVISOR = 'ADVISOR';
export const ADMIN = 'SORA_ADMIN';
export const CLIENT = 'CLIENT';

// Offer Types
export const REFINANCE_OFFER = 'refinance';

// React Query Defaults
export const DEFAULT_STALE_TIME_FIVE_MINUTES = 1000 * 60 * 5;

// Default Insights Carousel Break Points
export const DEFAULT_INSIGHTS_CAROUSEL_BREAK_POINTS = {
	superLargeDesktop: {
		// the naming can be any, depends on you.
		breakpoint: { max: 6000, min: 2000 },
		items: 3,
		slidesToSlide: 3
	},
	desktop: {
		breakpoint: { max: 2000, min: 1024 },
		items: 2,
		slidesToSlide: 2
	},
	tablet: {
		breakpoint: { max: 1024, min: 600 },
		items: 1
	},
	mobile: {
		breakpoint: { max: 599, min: 0 },
		items: 1
	}
};

// Default Insights Carousel Timer
export const DEFAULT_CAROUSEL_SPEED_EIGHT_SECONDS = 1000 * 8;

// Liability Confidence Level Mapping
export const LIABILITY_CONFIDENCE_LEVEL_MAPPING =
	process.env.REACT_APP_LIABILITY_CONFIDENCE_MAPPING;
