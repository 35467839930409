import { useMemo } from 'react';

// Our Components
import RequestDetailsItem from 'components/LoanRequestDetails/RequestDetails/RequestDetailsItem';

// Our Utils
import { dollarFormatter } from 'shared/utils';

function PersonalSpecificRequestDetails({
	totalAnnualIncome,
	totalAssetsValue,
	employmentStatus,
	state,
	zipCode
}) {
	const formattedAnnualIncome = useMemo(
		() => dollarFormatter.format(totalAnnualIncome ?? 0),
		[totalAnnualIncome]
	);

	const formattedTotalAssetsValue = useMemo(
		() => dollarFormatter.format(totalAssetsValue ?? 0),
		[totalAssetsValue]
	);

	// Personal Loan Details
	return (
		<>
			<RequestDetailsItem
				heading="Annual Income"
				value={formattedAnnualIncome}
			/>
			<RequestDetailsItem
				heading="Total assets value"
				value={formattedTotalAssetsValue}
			/>
			<RequestDetailsItem
				heading="Employment Status"
				value={employmentStatus}
			/>
			<RequestDetailsItem heading="State" value={state} />
			<RequestDetailsItem heading="Zip Code" value={zipCode} />
		</>
	);
}

export default PersonalSpecificRequestDetails;
