import {
	DataGrid,
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	GridToolbarExport
} from '@mui/x-data-grid';
import { Box } from '@mui/material';
import { useTheme } from '@emotion/react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import PropTypes from 'prop-types';
import { useNavigate, generatePath } from 'react-router-dom';

// Our Components
import OnboardingTasksNoRowOverlay from 'components/DataTable/OnboardingTasksNoRowOverlay';
import NoRowsOverlay from 'components/DataTable/NoRowsOverlay';
import NoLoanTasksOverlay from 'components/DataTable/NoLoanTasksOverlay';

// Our routes
import { ADVISOR_WALLET_ROUTE } from 'routes';

// Our utils
import { dataIsValid } from 'shared/utils';

function CustomToolbar() {
	return (
		<GridToolbarContainer>
			<GridToolbarColumnsButton />
			<GridToolbarFilterButton />
			<GridToolbarExport />
		</GridToolbarContainer>
	);
}

// TODO @Daniel add Prop Types to specify the shape of columns, and rows
function DataTable({
	columns,
	disableSelectionOnClick,
	defaultSortModel,
	enableToolbar,
	handleRowClick,
	height,
	noLoanTasks,
	pageSize,
	rowHeight,
	rows,
	rowsPerPageOptions,
	sx,
	withOnboardNowOverlay,
	withRowColorCheck
}) {
	const SoraTheme = useTheme();

	const setNoLoanState = () => {
		if (withOnboardNowOverlay && !noLoanTasks) {
			return OnboardingTasksNoRowOverlay;
		}
		if (noLoanTasks) {
			return NoLoanTasksOverlay;
		}
		return NoRowsOverlay;
	};

	return (
		// TODO @Daniel height shouldn't be a specific px value. See if we can make it responsive.
		<Box sx={{ height, width: '100%' }}>
			<DataGrid
				initialState={{
					sorting: {
						sortModel: defaultSortModel ?? []
					}
				}}
				rows={rows}
				disableSelectionOnClick={disableSelectionOnClick ?? false}
				columns={columns}
				pageSize={pageSize} // example is 5
				rowsPerPageOptions={[rowsPerPageOptions]}
				rowHeight={rowHeight}
				onRowClick={(rowData) => {
					const { row } = rowData;

					if (handleRowClick) {
						handleRowClick(row);
					}
				}}
				getRowClassName={(params) => {
					if (withRowColorCheck) {
						const hasStatusDataInRow = dataIsValid(
							params.row?.status
						);

						if (
							hasStatusDataInRow &&
							params?.row.status !== 'ACTIVE'
						)
							return 'MuiDataGrid-row-special';
					}

					return 'MuiDataGrid-row';
				}}
				autoPageSize
				sx={{
					...sx,
					backgroundColor:
						SoraTheme.palette.gradient
							.soraBlueToWhiteLinearGradient,
					'& .MuiDataGrid-columnSeparator': {
						display: 'none'
					},
					'& .MuiDataGrid-footerContainer': {
						borderTop: 'none',
						borderBottom: 'none'
					},
					'& .MuiDataGrid-row': {
						cursor: 'pointer'
					},
					'& .MuiDataGrid-row-special': {
						backgroundColor: `${SoraTheme.palette.warning.main}`,
						'&:hover': {
							backgroundColor: `${SoraTheme.palette.warning.mainHover}`
						},
						'&.Mui-selected': {
							backgroundColor: `${SoraTheme.palette.warning.main}`,
							'&:hover': {
								backgroundColor: `${SoraTheme.palette.warning.mainHover}`
							}
						}
					},
					'& .MuiDataGrid-columnHeader:focus': {
						outline: 'none'
					},
					'& .MuiDataGrid-columnHeader:focus-within': {
						outline: 'none'
					},
					'& .MuiDataGrid-cell:focus': {
						outline: 'none'
					},
					border: 'none'
				}}
				components={{
					ColumnSortedAscendingIcon: ArrowDropUpIcon,
					ColumnSortedDescendingIcon: ArrowDropDownIcon,
					Toolbar: enableToolbar ? CustomToolbar : null,
					NoRowsOverlay: setNoLoanState()
				}}
			/>
		</Box>
	);
}

const columnItemShape = {
	field: PropTypes.string.isRequired,
	headerName: PropTypes.string.isRequired,
	flex: PropTypes.number,
	props: PropTypes.object // props specifies mx props check component ColumnItem for links.
};

// Rows shape will be dependant on the columnItemShape.
DataTable.propTypes = {
	columns: PropTypes.arrayOf(PropTypes.shape(columnItemShape)).isRequired,
	disableSelectionOnClick: PropTypes.bool,
	enableToolbar: PropTypes.bool,
	height: PropTypes.number,
	pageSize: PropTypes.number,
	rowsPerPageOptions: PropTypes.number,
	rowHeight: PropTypes.number,
	withOnboardNowOverlay: PropTypes.bool,
	noLoanTasks: PropTypes.bool
};

DataTable.defaultProps = {
	disableSelectionOnClick: false,
	enableToolbar: false,
	height: 635,
	pageSize: 10,
	rowsPerPageOptions: 10,
	rowHeight: 52, // Default by MUI I.e. What we had
	withOnboardNowOverlay: false,
	noLoanTasks: false
};

export default DataTable;
