import { useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Box, IconButton, Typography } from '@mui/material';
import { EditOutlined, DeleteOutlineOutlined } from '@mui/icons-material';
import { useTheme } from '@emotion/react';

// Our Components
import AdminLoanInquiryModal from 'components/Modal/AdminLoanInquiryModal';
import ColumnItem from 'components/DataTable/ColumnItem';
import ColumnVerticalHeader from 'components/DataTable/ColumnVerticalHeader';
import DataTable from 'components/DataTable/DataTable';

// Our Utils
import { dollarFormatter } from 'shared/utils';

function LoanOffersTable({
	isLoanOfferAccepted,
	loanType,
	loanOffers,
	tradelineType
}) {
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [modalVersion, setModalVersion] = useState(null);
	const [selectedLoanOfferData, setSelectedLoanOfferData] = useState(null);

	const openModal = (version) => {
		const isEdit = version === 'edit';
		const isView = version === 'view';
		const isDelete = version === 'delete';

		if (isEdit) {
			setIsModalOpen(true);
			setModalVersion('edit');
			return;
		}
		if (isView) {
			setIsModalOpen(true);
			setModalVersion('view');
			return;
		}

		if (isDelete) {
			setIsModalOpen(true);
			setModalVersion('delete');
			return;
		}

		// Add mode
		setIsModalOpen(true);
		setIsModalOpen('add');
	};

	const closeModal = () => {
		setIsModalOpen(false);
		setModalVersion(null);
		setSelectedLoanOfferData(null);
	};

	const lenderDataKey = 'lender';
	const monthlyPaymentDataKey = 'monthlyPayment';
	const rateDataKey = 'rate';
	const upfrontCostDataKey = 'upfrontCost';
	const lastReviewedDataKey = 'lastReviewed';
	const requestDataKey = 'request';
	const offeredBy = 'offeredBy';

	const loanOfferDataWithIds = useMemo(
		() =>
			loanOffers.map((loanOffersData) => ({
				...loanOffersData,
				loanOfferId: loanOffersData.id,
				id: uuidv4()
			})),
		[loanOffers]
	);

	const columnsOffers = [
		ColumnItem(lenderDataKey, 'Lender', 2, {
			renderCell: (params) => {
				const lender = params.value;
				return <Typography variant="body1">{lender}</Typography>;
			}
		}),
		ColumnItem(offeredBy, 'Offered By', 1, {
			valueFormatter: (params) => {
				const offeredBy = params.value;
				if (!offeredBy) return 'NA';
				if (offeredBy === 'sora') return 'Sora';
				return offeredBy;
			}
		}),
		ColumnItem(monthlyPaymentDataKey, 'Monthly Pay', 1.1, {
			valueFormatter: (params) => {
				const monthlyPayment = params.value;
				if (!monthlyPayment && monthlyPayment !== 0) return 'NA';
				if (monthlyPayment === 0) return '$0';
				return dollarFormatter.format(monthlyPayment);
			}
		}),
		ColumnItem(rateDataKey, 'APR', 1.1, {
			valueFormatter: (params) => {
				const rate = params.value;
				if (!rate && rate !== 0) return 'NA';
				return `${rate}%`;
			}
		}),
		ColumnItem(upfrontCostDataKey, 'One time fees', 1, {
			valueFormatter: (params) => {
				const upfrontCost = params.value;
				if (!upfrontCost && upfrontCost !== 0) return 'NA';
				if (upfrontCost === 0) return '$0';
				return dollarFormatter.format(upfrontCost);
			}
		}),
		ColumnItem(lastReviewedDataKey, 'Last Reviewed', 1, {
			valueFormatter: (params) => {
				const lastReviewed = params.value;
				if (!lastReviewed) return 'NA';
				return lastReviewed;
			}
		}),
		ColumnItem(requestDataKey, 'Action', 1, {
			// eslint-disable-next-line react/no-unstable-nested-components
			renderHeader: ({ colDef }) => (
				<ColumnVerticalHeader headerName={colDef.headerName} />
			),
			renderCell: (params) => {
				const currentlySelectedLoanInquiry = params.row;
				const isManualOffer =
					currentlySelectedLoanInquiry.offeredBy === 'sora';

				const isEditable = isManualOffer && !isLoanOfferAccepted;

				return (
					<Box>
						<IconButton
							disabled={!isEditable}
							onClick={(e) => {
								e.stopPropagation();
								setSelectedLoanOfferData(
									currentlySelectedLoanInquiry
								);
								openModal('edit');
							}}
						>
							<EditOutlined
								sx={{
									color: isEditable
										? primary.black
										: primary.grey
								}}
							/>
						</IconButton>
						<IconButton
							disabled={!isManualOffer}
							sx={{ color: primary.black }}
							onClick={(e) => {
								e.stopPropagation();
								setSelectedLoanOfferData(
									currentlySelectedLoanInquiry
								);
								openModal('delete');
							}}
						>
							<DeleteOutlineOutlined
								sx={{
									color: isManualOffer
										? primary.black
										: primary.grey
								}}
							/>
						</IconButton>
					</Box>
				);
			}
		})
	];

	return (
		<>
			<Box
				sx={{
					width: '100%'
				}}
			>
				<DataTable
					columns={columnsOffers}
					rows={loanOfferDataWithIds}
					pageSize={10}
					rowsPerPageOptions={10}
					handleRowClick={(currentlySelectedLoanInquiry) => {
						setSelectedLoanOfferData(currentlySelectedLoanInquiry);
						openModal('view');
					}}
					disableSelectionOnClick
					withOnboardNowOverlay
				/>
			</Box>

			{/* Add Loan Offer Modal */}
			{isModalOpen && (
				<AdminLoanInquiryModal
					loanType={loanType}
					selectedLoanOfferData={selectedLoanOfferData}
					variant={modalVersion}
					isOpen={isModalOpen}
					setIsModalOpen={closeModal}
					tradelineType={tradelineType}
				/>
			)}
		</>
	);
}

export default LoanOffersTable;
