import { useQueryClient, useMutation } from 'react-query';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

// Our Query Keys
import { ERROR_MESSAGE_DATA, FIREBASEUSERCREDENTIALS } from 'shared/query-keys';

const useMutateLoginUser = () => {
	const auth = getAuth();
	const queryClient = useQueryClient();

	return useMutation(
		async ({ email, password }) => {
			const response = await signInWithEmailAndPassword(
				auth,
				email,
				password
			);

			const userToken = await response.user.getIdToken();

			return { userToken };
		},
		{
			onSuccess: (data) => {
				// if this mutation call is successful store the response to querycache
				queryClient.setQueryData(FIREBASEUSERCREDENTIALS, data);
			},
			onError: (error) => {
				queryClient.setQueryData(ERROR_MESSAGE_DATA, error.message);
			}
		}
	);
};

export default useMutateLoginUser;
