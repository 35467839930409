import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';
import { format, parseISO } from 'date-fns';
import { useState, useMemo } from 'react';
import { useTheme } from '@emotion/react';

// Our Components
import ApprovalButton from 'components/LoanRequestDetails/DocumentCheckList/ApprovalButton';
import ColumnItem from 'components/DataTable/ColumnItem';
import DataTable from 'components/DataTable/DataTable';
import Loader from 'components/Loader';
import Modal from 'components/Modal/Modal';
import { SecondaryButton } from 'components/Button/Button';
import PreviewPdf from 'components/Tasks/PreviewPdf';
import PreviewImage from 'components/Tasks/PreviewImage';

// Our Hooks
import useGetRelatedDocuments from 'hooks/newLoanInquiries/useGetLoanRequestRelatedDocuments';
import useMutateLoanRequestDocumentDownload from 'hooks/newLoanInquiries/useMutateLoanRequestDocumentDownload';

const isValidImage = (url) => {
	const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.pdf', '.svg'];
	const extension = url.substring(url.lastIndexOf('.')).toLowerCase();
	return imageExtensions.includes(extension) ? url : null;
};

function LoanRequestRelatedDocuments({ loanRequestId }) {
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;
	const { data, isLoading, isSuccess, error } =
		useGetRelatedDocuments(loanRequestId);

	// upload modal
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedDocument, setSelectedDocument] = useState(null);

	const downloadDocument = useMutateLoanRequestDocumentDownload();

	const documentImage = useMemo(() => {
		if (!selectedDocument) return null;

		const { loanDocuments } = selectedDocument;
		const [document] = loanDocuments;
		const { url } = document;

		if (!isValidImage(url)) return null;

		return url;
	}, [selectedDocument]);

	const documentData = useMemo(() => {
		if (!selectedDocument) return null;

		const { loanDocuments } = selectedDocument;

		const [document] = loanDocuments;

		const { newLoanId, masterDocumentId, clientId } = document;

		return { loanRequestId: newLoanId, masterDocumentId, clientId };
		// should have
	}, [selectedDocument]);

	const isDocumentValid = documentImage !== null;

	const isPdf = documentImage !== null && documentImage.includes('.pdf');
	const isImage =
		documentImage !== null && !isPdf && isValidImage(documentImage);
	const isValidPdf = isDocumentValid && isPdf;

	const closeModal = () => {
		setIsModalOpen(false);
		setSelectedDocument(null);
	};

	const isCompleted = 'isCompleted';
	const nameKey = 'name';
	const createdDateKey = 'createdDate';
	const actionColumnOne = 'actionColumnOne';
	const loanDocumentsKey = 'loanDocuments';
	const documentDescriptionKey = 'documentDescription';

	const LoanRequestDocumentsColumns = [
		ColumnItem(isCompleted, '', 1, {
			sortable: false,
			renderCell: (params) => {
				const isUploaded = params.row[loanDocumentsKey].length > 0;

				if (isUploaded) {
					return (
						<Tooltip title="File has been uploaded" placement="top">
							<Box>
								<CheckCircle
									sx={{
										width: '1.25rem',
										height: '1.25rem',
										color: primary.indigo,
										borderRadius: 200,
										'&:hover': {
											opacity: 0.7,
											cursor: 'pointer',
											color: primary.indigo
										}
									}}
								/>
							</Box>
						</Tooltip>
					);
				}
				if (!isUploaded) {
					return (
						<Tooltip title="No file uploaded" placement="top">
							<Box>
								<RadioButtonUnchecked
									sx={{
										width: '1.25rem',
										height: '1.25rem',
										color: primary.grey,
										borderRadius: 200
									}}
								/>
							</Box>
						</Tooltip>
					);
				}

				return 'Error';
			}
		}),
		ColumnItem(nameKey, '', 5, {
			sortable: false,
			renderCell: (params) => {
				const textShownInPage = params.row[documentDescriptionKey];
				const isUploaded = !params.row[loanDocumentsKey].length > 0;

				return (
					<Tooltip title={textShownInPage} placement="top">
						<Typography
							variant="body1"
							sx={{
								color: isUploaded
									? primary.grey
									: primary.black,
								textDecoration: isUploaded
									? 'none'
									: 'underline',
								textUnderlineOffset: isUploaded ? 0 : '2px'
							}}
						>
							{textShownInPage}
						</Typography>
					</Tooltip>
				);
			}
		}),
		ColumnItem(createdDateKey, '', 2, {
			sortable: false,
			valueGetter: (params) => {
				const singleDocumentData = params.row[loanDocumentsKey][0];
				const documentUploadedDate = parseISO(
					singleDocumentData?.createdDate
				);

				if (singleDocumentData !== undefined) {
					const formattedDate = format(
						new Date(documentUploadedDate),
						'MM/dd/yyyy'
					);
					return formattedDate;
				}

				return 'NA';
			},

			renderCell: (params) => {
				const isUploaded = !params.row[loanDocumentsKey].length > 0;
				return (
					<Typography
						variant="body1"
						sx={{
							color: isUploaded ? primary.grey : primary.black
						}}
					>
						{params.value}
					</Typography>
				);
			}
		}),
		ColumnItem(actionColumnOne, '', 1.5, {
			sortable: false,
			// eslint-disable-next-line consistent-return
			renderCell: (params) => {
				const documentId = params?.row[loanDocumentsKey][0]?.id;

				const loanDocuments = params.row[loanDocumentsKey];
				const currentDocument = loanDocuments.find(
					({ id }) => id === documentId
				);

				return (
					<Box
						sx={{
							width: '100%',
							display: 'flex'
						}}
					>
						<ApprovalButton
							variant="up"
							currentDocument={currentDocument}
						/>
						<ApprovalButton
							variant="down"
							currentDocument={currentDocument}
						/>
					</Box>
				);
			}
		})
	];

	if (error)
		return (
			<>
				<Typography variant="h5" sx={{ marginTop: 20 }}>
					There was an error fetching your loan request.
				</Typography>
				<Typography variant="h5" sx={{ marginTop: 2 }}>
					Please refresh the page, if issue persist please reach out
					to sora at contact@sorafinance.com
				</Typography>
			</>
		);

	if (isLoading) {
		return (
			<Grid
				container
				item
				xs={12}
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center'
				}}
			>
				<Loader size={70} />
			</Grid>
		);
	}

	if (isSuccess) {
		return (
			<>
				<Grid container>
					<Grid item xs={12}>
						<DataTable
							disableSelectionOnClick
							pageSize={4}
							columns={LoanRequestDocumentsColumns}
							rows={data}
							rowsPerPageOptions={4}
							isRowSelectable={false}
							withRowColorCheck
							height={360}
							rowHeight={60}
							sx={{ top: '-40px', marginBottom: 0 }}
							handleRowClick={(row) => {
								const isRowDisabled =
									row[loanDocumentsKey].length === 0;
								if (isRowDisabled) return;
								setSelectedDocument(row);
								setIsModalOpen(true);
							}}
						/>
					</Grid>
				</Grid>

				{selectedDocument && (
					<Modal
						title="Preview Document"
						subtitle="Click on download to download document"
						isOpen={isModalOpen}
						handleClose={closeModal}
					>
						<Box
							sx={{
								width: '100%',
								height: '100%',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
								gap: 4
							}}
						>
							{!isDocumentValid && (
								<>
									<Typography variant="body1">
										This file cannot be previewed
									</Typography>
									<SecondaryButton
										onClick={() => {
											downloadDocument.mutate(
												documentData
											);
											closeModal();
										}}
									>
										Download
									</SecondaryButton>
								</>
							)}

							{isImage && (
								<PreviewImage documentData={documentData}>
									<SecondaryButton
										onClick={() => {
											downloadDocument.mutate(
												documentData
											);
											closeModal();
										}}
									>
										Download
									</SecondaryButton>
								</PreviewImage>
							)}

							{isValidPdf && (
								<PreviewPdf documentData={documentData}>
									<SecondaryButton
										onClick={() => {
											downloadDocument.mutate(
												documentData
											);
											closeModal();
										}}
									>
										Download
									</SecondaryButton>
								</PreviewPdf>
							)}
						</Box>
					</Modal>
				)}
			</>
		);
	}
}

export default LoanRequestRelatedDocuments;
