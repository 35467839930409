import { Grid, Link as ExternalLink, Typography } from '@mui/material';
// Our theme
import SoraTheme from 'theme';

function Footer() {
	return (
		<Grid
			container
			spacing={2}
			sx={{
				height: '48px',
				maxHeight: '48px',
				marginTop: 2,
				paddingTop: 2,
				marginBottom: 2,
				flexGrow: 1
			}}
		>
			<Grid
				item
				xs={12}
				sx={{
					display: 'flex',
					justifyContent: 'center',
					flexGrow: 1,
					borderTop: `0.25px solid ${SoraTheme.palette.primary.black}`
				}}
			>
				<Typography
					variant="footerText"
					sx={{
						textDecoration: 'none',
						display: 'flex'
					}}
				>
					© 2024 Sora Finance
				</Typography>
				<ExternalLink
					href="https://www.sorafinance.com/privacy-policy"
					variant="footerText"
					sx={{
						marginLeft: 4,
						marginRight: 4,
						display: 'flex',
						color: `${SoraTheme.palette.primary.black}`
					}}
				>
					Privacy Policy
				</ExternalLink>
				<ExternalLink
					href="https://www.sorafinance.com/terms-of-service"
					variant="footerText"
					sx={{
						display: 'flex',
						color: `${SoraTheme.palette.primary.black}`
					}}
				>
					Terms of Service
				</ExternalLink>
			</Grid>
		</Grid>
	);
}

export default Footer;
