import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { pdfjs } from 'react-pdf';

// Firebase and google analytics setup
import { initializeApp } from 'firebase/app';

// Scroll to top util
import ScrollToTop from 'hooks/utilityHook/useScrollToTop';

// Used by MUI in order to serve our custom SoraTheme
import { ThemeProvider } from '@mui/material/styles';

// Sora's defined colors & typography
import SoraTheme from './theme';

// Provides css resets for a simple baseline for styles to be built upon
import { CssBaseline } from '@mui/material';

import App from './App';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
initializeApp(firebaseConfig);

ReactDOM.render(
	<HelmetProvider>
		<React.StrictMode>
			<ThemeProvider theme={SoraTheme}>
				<CssBaseline />
				<BrowserRouter>
					<ScrollToTop />
					<App />
				</BrowserRouter>
			</ThemeProvider>
		</React.StrictMode>
	</HelmetProvider>,
	document.getElementById('root')
);
