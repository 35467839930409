import { useQuery } from 'react-query';

// Our Mutations
import useMutateCreateClientSpecificChannel from 'hooks/talkjs/mutations/useMutateCreateClientSpecificChannel';

// Our Query Keys
import { TALKJS_CURRENT_CONVERSATION_ID } from 'shared/query-keys';

// Our Constants
import { DEFAULT_STALE_TIME_FIVE_MINUTES } from 'shared/constants';

function useGetCurrentConversation(advisorId, clientId, clientInfo) {
	const createClientSpecificChannel = useMutateCreateClientSpecificChannel();

	return useQuery(
		[TALKJS_CURRENT_CONVERSATION_ID, advisorId, clientId],
		async () => {
			// Create Room now
			const roomId = await createClientSpecificChannel.mutateAsync({
				advisorId,
				clientInfo
			});

			return roomId;
		},
		{
			staleTime: DEFAULT_STALE_TIME_FIVE_MINUTES
		}
	);
}

export default useGetCurrentConversation;
