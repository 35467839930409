import { forwardRef } from 'react';
import { Box } from '@mui/material';
import styled from 'styled-components';

// Our Components
import LoanDetailsSoraBrandingInfo from 'components/PortableDocumentFormats/LoanDetailsSummaryPDF/LoanDetailsSoraBrandingInfo';
import LoanDetailsSummaryHeading from 'components/PortableDocumentFormats/LoanDetailsSummaryPDF/LoanDetailsSummaryHeading';
import LoanDetailsClientDetailsSection from 'components/PortableDocumentFormats/LoanDetailsSummaryPDF/LoanDetailsClientDetailsSection';
import LoanRequestDetailsSection from 'components/PortableDocumentFormats/LoanDetailsSummaryPDF/LoanRequestDetailsSection';
import LoanDetailsLoanOfferSection from 'components/PortableDocumentFormats/LoanDetailsSummaryPDF/LoanDetailsLoanOfferSection';
import PDFDivider from 'components/PortableDocumentFormats/Section/PDFDivider';
import PDFSection from 'components/PortableDocumentFormats/Section/PDFSection';

// Prop Types
import CLIENT_DATA_PROP_TYPE from 'shared/PropTypes/LoanRequestDetailsHeading/ClientDataPropType';

// This forces MUI to adhere to printing layout as landscape!
const StyledBox = styled(Box)`
	@page {
		margin: 30px !important;
	}
`;

const LoanDetailsSummaryPDF = forwardRef(
	(
		{
			isLoanOfferAccepted,
			loanRequestId,
			loanRequestStatus,
			loanRequestType
		},
		ref
	) => {
		return (
			<StyledBox
				ref={ref}
				sx={{
					visibility: 'hidden',
					width: 0,
					height: 0,
					'@media print': {
						visibility: 'visible',
						display: 'flex',
						padding: 2,
						flexDirection: 'column',
						justifyContent: 'center',
						width: '100%',
						height: '100%'
					}
				}}
			>
				<LoanDetailsSoraBrandingInfo />
				<LoanDetailsSummaryHeading
					loanRequestType={loanRequestType}
					loanRequestId={loanRequestId}
					loanRequestStatus={loanRequestStatus}
				/>

				<LoanDetailsClientDetailsSection />

				<PDFDivider />

				<PDFSection sx={{ display: 'flex', gap: 8, marginTop: 4 }}>
					<LoanRequestDetailsSection />
					{isLoanOfferAccepted && <LoanDetailsLoanOfferSection />}
				</PDFSection>
			</StyledBox>
		);
	}
);

LoanDetailsClientDetailsSection.propTypes = {
	clientData: CLIENT_DATA_PROP_TYPE
};
LoanDetailsClientDetailsSection.defaultProps = {
	clientData: {}
};

export default LoanDetailsSummaryPDF;
