import { Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';

// Our Components
import Footer from 'components/Footer/Footer';
import MobileNavBarToggle from 'components/Frame/MobileNavBarToggle';
import DesktopLeftNav from 'components/Frame/DesktopLeftNav';

function ContainerFrameWithNav() {
	// This component is where the switch between mobile and desktop versions occur
	return (
		<Grid container>
			<Grid
				item
				sx={{
					display: { xs: 'block', lg: 'none' },
					maxHeight: 60
				}}
			>
				<MobileNavBarToggle />
			</Grid>

			<Grid
				item
				xs={0}
				lg={2}
				sx={{ display: { xs: 'none', lg: 'block' } }}
			>
				<DesktopLeftNav />
			</Grid>

			<Grid
				item
				xs={12}
				lg={10}
				sx={{
					alignItems: 'flex-end',
					overflow: 'hidden',
					height: 'inherit'
				}}
			>
				<Grid
					container
					sx={{
						marginLeft: 4,
						marginRight: 4,
						width: 'inherit',
						height: 'inherit'
					}}
				>
					<Grid
						item
						xs={12}
						sx={{
							minHeight: '100vh',
							marginTop: 6,
							marginLeft: 2,
							marginRight: 2
						}}
					>
						<Outlet />
					</Grid>
					<Grid
						item
						xs={12}
						sx={{
							justifyContent: 'center',
							alignItems: 'center',
							gap: 5,
							marginLeft: 2
						}}
					>
						<Footer />
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

export default ContainerFrameWithNav;
