import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTheme } from '@emotion/react';

// Our Assets
import SoraLogo from 'assets/images/sora_logos/SoraLogoBlack.svg';

import Footer from 'components/Footer/Footer';

function ErrorPage() {
	const SoraTheme = useTheme();

	return (
		<>
			<Box
				sx={{
					marginTop: 4,
					marginBottom: 20,
					textAlign: 'center',
					color: SoraTheme.palette.primary.soraBlue
				}}
			>
				<img src={SoraLogo} alt="Sora logo" width="208" height="76" />
				<Typography
					variant="h1"
					gutterBottom
					component="div"
					sx={{
						marginTop: 10
					}}
				>
					404
				</Typography>
				<Typography
					variant="h3"
					gutterBottom
					component="div"
					sx={{
						marginTop: 6
					}}
				>
					Page Not Found
				</Typography>
				<Link to="/">Home</Link>
			</Box>
			<Box sx={{ position: 'absolute', bottom: 0, width: '100%' }}>
				<Footer />
			</Box>
		</>
	);
}

export default ErrorPage;
