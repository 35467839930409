import PropTypes from 'prop-types';

// Our Components
import TextInput from 'components/Input/TextInput';

// Input Types
import { TEXT } from 'components/Input/Types';

function LenderInput({ required, lender, setLender, label, subLabel }) {
	return (
		<TextInput
			required={required}
			type={TEXT}
			label={label}
			subLabel={subLabel}
			value={lender}
			onChange={setLender}
		/>
	);
}

LenderInput.propTypes = {
	lender: PropTypes.string.isRequired,
	setLender: PropTypes.func.isRequired,
	label: PropTypes.string,
	subLabel: PropTypes.string,
	required: PropTypes.bool
};

LenderInput.defaultProps = {
	lender: '',
	setLender: () => {},
	label: 'Lender',
	subLabel: '',
	required: false
};

export default LenderInput;
