import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

// Our Prop Types
import CommonProps from 'shared/prop-types';

function PDFHeadingText({ componentType, headingText, headingVariant, sx }) {
	return (
		<Typography component={componentType} variant={headingVariant} sx={sx}>
			{headingText}
		</Typography>
	);
}

PDFHeadingText.propTypes = {
	headingText: PropTypes.string.isRequired,
	headingVariant: PropTypes.string,
	componentType: PropTypes.string,
	sx: CommonProps.sx
};

PDFHeadingText.defaultProps = {
	componentType: 'h6',
	headingVariant: 'h3Gascogne',
	sx: {}
};

export default PDFHeadingText;
