// Our Utils
import { decodedLoanType } from 'shared/utils';

const selectLoanRequestDetails = (state) => {
	const { newLoanDetails } = state;

	// Loan Request Specific Details

	const {
		additionalNotes,
		loanAmount,
		offerType,
		ownershipTenure,
		tradelineType
	} = newLoanDetails;

	return {
		additionalNotes,
		offerType,
		loanAmount,
		tradelineType,
		ownershipTenure,
		loanRequestType: decodedLoanType(tradelineType, offerType)
	};
};

export default selectLoanRequestDetails;
