export const REQUEST_IN_PROCESS = 'Request in Process';
export const SEARCH_RATES = 'Search Rates';
export const ADD_RATES = 'Add Rates';
export const RATE_SELECTED = 'Rate Selected';
export const APPROVE_REQUEST = 'Approve Request';
export const CONTACT_LENDER = 'Contact Lender';
export const IN_UNDERWRITING = 'In Underwriting';
export const PENDING_DISBURSEMENT = 'Pending Disbursement';
export const ADMIN_COMPLETED = 'ADMIN_COMPLETED';
export const ADMIN_CANCELLED = 'ADMIN_CANCELLED';
export const ADMIN_REJECTED = 'ADMIN_REJECTED';

export const ADMIN_LOAN_REQUEST_STEPS = [
	REQUEST_IN_PROCESS,
	SEARCH_RATES,
	ADD_RATES,
	RATE_SELECTED,
	APPROVE_REQUEST,
	CONTACT_LENDER,
	IN_UNDERWRITING,
	PENDING_DISBURSEMENT,
	ADMIN_COMPLETED,
	ADMIN_CANCELLED,
	ADMIN_REJECTED
];
