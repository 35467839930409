import { Box, Typography } from '@mui/material';

// This component is assumed to be used in the DataGrid
function ColumnVerticalHeader({ headerName }) {
	const splitColDef = headerName.split(' ');
	const headingAbove = splitColDef[0];
	const headingBelow = splitColDef[1];

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				width: '100%',
				height: '100%',
				paddingBottom: 0.5
			}}
		>
			<Typography
				variant="subtitle2"
				sx={{
					display: 'inline-block',
					whiteSpace: 'pre-line',
					width: '100%',
					marginBottom: 0.5
				}}
			>
				{headingAbove}
			</Typography>
			<Typography
				variant="subtitle2"
				sx={{
					display: 'inline-block',
					whiteSpace: 'pre-line',
					width: '100%'
				}}
			>
				{headingBelow}
			</Typography>
		</Box>
	);
}

export default ColumnVerticalHeader;
