import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { isDate } from 'validator';
import { useMemo } from 'react';

// Our Components
import StandardDatePicker from 'components/DatePicker/StandardDatePicker';
import TextInput from 'components/Input/TextInput';

// Text Input types
import { TEXT } from 'components/Input/Types';

// Constants
import { EMPLOYMENT_STATUS_OPTIONS, US_STATE_OPTIONS } from 'shared/constants';

function ReviewProfileForm({
	withDisabled,
	firstName,
	setFirstName,
	lastName,
	setLastName,
	dob,
	setDOB
}) {
	const opacity = withDisabled ? 0.6 : 1;
	const disabledSubLabel = withDisabled ? 'Disabled' : '';

	const isValidDate = useMemo(() => {
		const isStringDate = typeof dob === 'string';
		const isDateType = dob instanceof Date;
		if (isStringDate && dob !== '') {
			const isDateCheckWithConversion = isDate(new Date(dob));
			return isDateCheckWithConversion;
		}

		if (isDateType) {
			return isDate(dob);
		}
		// if dob is null this returns false;
		return false;
	}, [dob]);

	return (
		<>
			{/* ROW 1 */}
			<Grid item xs={6}>
				<TextInput
					disabled={withDisabled}
					label="First name"
					value={firstName}
					onChange={setFirstName}
					type={TEXT}
					sx={{ opacity }}
					subLabel={disabledSubLabel}
				/>
			</Grid>

			<Grid item xs={6}>
				<TextInput
					disabled={withDisabled}
					label="Last name"
					value={lastName}
					onChange={setLastName}
					type={TEXT}
					sx={{ marginBottom: 4, opacity }}
					subLabel={disabledSubLabel}
				/>
			</Grid>

			<Grid item xs={6}>
				<StandardDatePicker
					disabled={withDisabled}
					id="dob"
					label="Date of birth"
					helperText={
						withDisabled ? 'Disabled' : 'Enter your birth date'
					}
					labelSx={{ opacity }}
					onChange={setDOB}
					value={dob}
					error={dob === null || !isValidDate}
					errorText="Date of birth needs to be a valid date"
					inputProps={{
						tabIndex: '3'
					}}
					sx={{ opacity }}
				/>
			</Grid>
		</>
	);
}

ReviewProfileForm.propTypes = {
	withDisabled: PropTypes.bool,
	firstName: PropTypes.string.isRequired,
	setFirstName: PropTypes.func.isRequired,
	lastName: PropTypes.string.isRequired,
	setLastName: PropTypes.func.isRequired,
	dob: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
		.isRequired,
	setDOB: PropTypes.func.isRequired,
	citizenshipType: PropTypes.string.isRequired,
	setCitizenshipType: PropTypes.func.isRequired,
	isVeteran: PropTypes.oneOf(['Yes', 'No', '']).isRequired,
	setIsVeteran: PropTypes.func.isRequired,
	educationRating: PropTypes.string.isRequired,
	setEducationRating: PropTypes.func.isRequired,
	employmentStatus: PropTypes.oneOf(EMPLOYMENT_STATUS_OPTIONS).isRequired,
	setEmploymentStatus: PropTypes.func.isRequired,
	addressLine1: PropTypes.string.isRequired,
	setAddressLine1: PropTypes.func.isRequired,
	addressLine2: PropTypes.string.isRequired,
	setAddressLine2: PropTypes.func.isRequired,
	city: PropTypes.string.isRequired,
	setCity: PropTypes.func.isRequired,
	state: PropTypes.oneOf([...US_STATE_OPTIONS, '']).isRequired,
	setState: PropTypes.func.isRequired,
	zipCode: PropTypes.string.isRequired,
	setZipCode: PropTypes.func.isRequired,
	creditScore: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
		.isRequired,
	setCreditScore: PropTypes.func.isRequired,
	monthlyRentPayment: PropTypes.string.isRequired,
	setMonthlyRentPayment: PropTypes.func.isRequired,
	employer: PropTypes.string.isRequired,
	setEmployer: PropTypes.func.isRequired
};

ReviewProfileForm.defaultProps = {
	withDisabled: true
};

export default ReviewProfileForm;
