import { Box } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useCallback } from 'react';

// Our Components
import LoanDetailsDataBlock from 'components/PortableDocumentFormats/LoanDetailsDataBlock';
import PDFSection from 'components/PortableDocumentFormats/Section/PDFSection';
import PDFHeadingText from 'components/PortableDocumentFormats/Heading/PDFHeadingText';

// Our Hooks
import useGetLoanRequestDetails from 'hooks/newLoanInquiries/useGetLoanRequestDetails';

// Our Selectors
import selectLoanDetailsSummary from 'selectors/LoanRequestDetails/selectLoanDetailsSummary';

// Our Utils
import { dollarFormatter, dataIsValid } from 'shared/utils';

const validCreditScore = (creditScore) =>
	dataIsValid(creditScore) && creditScore.length > 0;

const LoanDetailsClientDetailsSection = () => {
	const SoraTheme = useTheme();
	const { indigo } = SoraTheme.palette.primary;

	const urlString = window.location.search;
	const urlParams = new URLSearchParams(urlString);
	const loanRequestId = urlParams.get('loanRequestId');

	const memoizedSelectDetailsSummary = useCallback(
		selectLoanDetailsSummary,
		[]
	);

	const { data } = useGetLoanRequestDetails(
		loanRequestId,
		memoizedSelectDetailsSummary
	);

	const {
		advisorEmail,
		advisorName,
		clientAddress,
		clientPersonalInfo,
		clientFinancialInfo,
		companyName
	} = data;

	const { clientFullName } = clientPersonalInfo;

	const { creditScore, totalAnnualIncome } = clientFinancialInfo;

	// Formatted
	const formattedAnnualIncome = dollarFormatter.format(
		totalAnnualIncome ?? 0
	);

	const formattedCreditScore = validCreditScore(creditScore)
		? creditScore
		: 'NA';

	const clientSectionData = [
		{ label: 'Financial Advisor Name', content: advisorName },
		{ label: 'Financial Advisor Email', content: advisorEmail },
		{ label: 'Financial Advisor Company', content: companyName ?? 'NA' },
		{ label: 'Client Name', content: clientFullName },
		{ label: 'Credit Score', content: formattedCreditScore },
		{ label: 'Annual Income', content: formattedAnnualIncome }
	];

	const { addressLine1, addressLine2, city, state, zipCode } = clientAddress;

	const safeAddress =
		dataIsValid(addressLine1) && dataIsValid(addressLine2)
			? `${addressLine1} ${addressLine2}`
			: 'NA';

	const clientAddressData = [
		{
			label: 'Address',
			content: safeAddress
		},
		{ label: 'City', content: city ?? 'NA' },
		{ label: 'State', content: state ?? 'NA' },
		{ label: 'Zip Code', content: zipCode ?? 'NA' }
	];

	return (
		<Box
			sx={{
				marginTop: 2,
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'flex-start'
			}}
		>
			<PDFHeadingText
				headingVariant="h3Gascogne"
				headingText="Client Details"
				sx={{ color: indigo, marginBottom: 4 }}
			/>

			<PDFSection sx={{ display: 'flex', gap: 8 }}>
				<LoanDetailsDataBlock detailsData={clientSectionData} />
				<LoanDetailsDataBlock detailsData={clientAddressData} />
			</PDFSection>
		</Box>
	);
};

export default LoanDetailsClientDetailsSection;
