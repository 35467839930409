import axiosInstance from 'services/API/API';
import { useQuery } from 'react-query';
import { loanRequestUrl } from 'shared/api-urls';

// Query Key
import { LOAN_REQUEST } from 'shared/query-keys';

function useGetLoanRequestDetails(loanRequestId, select) {
	return useQuery(
		[LOAN_REQUEST, loanRequestId],
		async () => {
			const res = await axiosInstance.get(
				`${loanRequestUrl}/${loanRequestId}`
			);

			return res.data.data;
		},
		{
			select: (data) => {
				if (select) return select(data);
				return data;
			},
			refetchOnWindowFocus: false
		}
	);
}

export default useGetLoanRequestDetails;
