/* eslint-disable import/no-anonymous-default-export */
import PropTypes from 'prop-types';

// Our Constants
import {
	AUTO,
	CREDITCARD,
	COLLECTION,
	COMMERCIAL,
	HELOC,
	MORTGAGE,
	PERSONAL,
	STUDENT
} from 'shared/constants';

const sx = PropTypes.oneOfType([
	PropTypes.arrayOf(
		PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
	),
	PropTypes.func,
	PropTypes.object
]);

const typography = PropTypes.oneOf([
	'h1',
	'h2',
	'h3',
	'h4',
	'h5',
	'h6',
	'subtitle1',
	'subtitle2',
	'body1',
	'body2',
	'button',
	'caption',
	'overline',
	'h1Gascogne',
	'h2Gascogne',
	'h3Gascogne'
]);

const supportedWalletLiabilities = PropTypes.oneOf([
	AUTO,
	CREDITCARD,
	HELOC,
	MORTGAGE,
	PERSONAL,
	STUDENT
]);

const supportedLiabilities = PropTypes.oneOf([
	AUTO,
	CREDITCARD,
	COMMERCIAL,
	COLLECTION,
	HELOC,
	MORTGAGE,
	PERSONAL,
	STUDENT
]);

const reactQueryPropType = PropTypes.shape({
	status: PropTypes.string,
	isLoading: PropTypes.bool,
	isSuccess: PropTypes.bool,
	isError: PropTypes.bool,
	isIdle: PropTypes.bool,
	dataUpdatedAt: PropTypes.number,
	// eslint-disable-next-line react/forbid-prop-types
	error: PropTypes.any,
	errorUpdatedAt: PropTypes.number,
	failureCount: PropTypes.number,
	isFetched: PropTypes.bool,
	isFetchedAfterMount: PropTypes.bool,
	isFetching: PropTypes.bool,
	isRefetching: PropTypes.bool,
	isLoadingError: PropTypes.bool,
	isPlaceholderData: PropTypes.bool,
	isPreviousData: PropTypes.bool,
	isRefetchError: PropTypes.bool,
	isStale: PropTypes.bool
});

export default {
	reactQueryPropType,
	sx,
	supportedWalletLiabilities,
	supportedLiabilities,
	typography
};
