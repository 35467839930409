import { Grid } from '@mui/material';

// Our components
import Dropdown from 'components/Dropdown/Dropdown';
import TextInput from 'components/Input/TextInput';

// Our constants
import { MORTGAGE_LOAN_TENURE_OPTIONS } from 'shared/constants';

// Input Types
import { NUMBER } from 'components/Input/Types';

function LoanTermDropdown({
	ownershipTenure,
	setOwnershipTenure,
	isMortgage,
	interestRateType,
	setInterestRateType,
	termYear,
	setTermYear
}) {
	if (isMortgage)
		return (
			<Grid item xs={6}>
				<Dropdown
					items={MORTGAGE_LOAN_TENURE_OPTIONS}
					selected={ownershipTenure}
					onChange={setOwnershipTenure}
					variant="outlined"
					label="Loan Term"
				/>
			</Grid>
		);

	return (
		<>
			<Grid item xs={6}>
				<TextInput
					type={NUMBER}
					label="Loan Term"
					value={termYear}
					onChange={setTermYear}
					subLabel="In Years"
				/>
			</Grid>

			<Grid item xs={6}>
				<Dropdown
					items={['Fixed', 'Variable']}
					selected={interestRateType}
					onChange={setInterestRateType}
					variant="outlined"
					label="Interest rate type"
				/>
			</Grid>
		</>
	);
}

export default LoanTermDropdown;
