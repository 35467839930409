import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { useEffect, useState, useMemo } from 'react';
import { Document, Page } from 'react-pdf';
import { Box, Typography } from '@mui/material';

// Our Components
import LoadingSkeleton from 'components/Frame/LoadingSkelton';
import { SecondaryButton } from 'components/Button/Button';

// Our hooks
import useMutateLoanRequestDocumentDownload from 'hooks/newLoanInquiries/useMutateLoanRequestDocumentDownload';

function PreviewPdf({ children, documentData }) {
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
	const downloadDocument = useMutateLoanRequestDocumentDownload(false);

	const { isLoading, isSuccess, data } = downloadDocument;

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
	}

	useEffect(() => {
		downloadDocument.mutate(documentData);
	}, []);

	const cachedPDF = useMemo(() => {
		if (isSuccess && data) {
			const { fileContent } = data;
			return { data: atob(fileContent) };
		}
		return null;
	}, [data]);

	if (isLoading || !data) return <LoadingSkeleton />;

	if (isSuccess) {
		return (
			<Box>
				<Document
					file={cachedPDF}
					onLoadSuccess={onDocumentLoadSuccess}
					loading={<LoadingSkeleton />}
				>
					<Page pageNumber={pageNumber} />
				</Document>
				<Typography variant="body1" sx={{ marginTop: 2 }}>
					Page {pageNumber} of {numPages}
				</Typography>
				<Box sx={{ marginTop: 2, display: 'flex', gap: 2 }}>
					<SecondaryButton
						onClick={() => {
							if (pageNumber > 1) {
								setPageNumber(pageNumber - 1);
							}
						}}
					>
						Previous
					</SecondaryButton>
					<SecondaryButton
						onClick={() => {
							if (pageNumber < numPages) {
								setPageNumber(pageNumber + 1);
							}
						}}
					>
						Next
					</SecondaryButton>
					{children}
				</Box>
			</Box>
		);
	}

	return (
		<Box>
			<Typography variant="body1">
				Failed to load PDF <br />
				<br />
				Please flag in UAT
			</Typography>
		</Box>
	);
}

export default PreviewPdf;
