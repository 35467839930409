import { Helmet } from 'react-helmet-async';
import { Typography } from '@mui/material';

function NewLoanRequestError() {
	return (
		<>
			<Helmet>
				<title>Loan Request Details</title>
			</Helmet>
			<Typography variant="h5" sx={{ marginTop: 20 }}>
				There was an error fetching your loan request.
			</Typography>
			<Typography variant="h5" sx={{ marginTop: 2 }}>
				Please refresh the page, if issue persist please reach out to
				sora at contact@sorafinance.com
			</Typography>
		</>
	);
}

export default NewLoanRequestError;
