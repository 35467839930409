import { Box, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

// Our Prop Types
import CommonProps from 'shared/prop-types';

function LoanDetailsDataBlock({ detailsData, containerSx }) {
	return (
		<Box sx={containerSx}>
			{detailsData.map(({ label, content }) => {
				const key = uuidv4();

				return (
					<Typography
						key={key}
						variant="body1"
						sx={{ marginBottom: 3 }}
					>
						<strong style={{ marginRight: '8px' }}>{label}</strong>
						{content}
					</Typography>
				);
			})}
		</Box>
	);
}

LoanDetailsDataBlock.propTypes = {
	containerSx: CommonProps.sx
};

LoanDetailsDataBlock.defaultProps = {
	containerSx: {}
};

export default LoanDetailsDataBlock;
