const LoanOfferTemplate = ({
	loanAmount,
	loanTerm,
	loanType,
	offerType,
	creditScore,
	annualIncome,
	state,
	emailData
}) => {
	const { email, subject } = emailData;
	const emailBody = `
        Hi,

        Hope you're having a great week.  Would you be interested in the following ${loanType}?  
        
        Amount requested: ${loanAmount}
        
        Loan term: ${loanTerm}
        
        Type of request: ${offerType}
        
        Client's credit score: ${creditScore !== '' ? creditScore : 'NA'}
        
        Annual income: ${annualIncome}
        
        Client location: ${state}

        
        If so, could you please share with me the following information?
        
        Annual interest rate
        
        Monthly payment expected
        
        Thank you so much for all your help!
        
        Best,
        `;

	const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(emailBody)}`;
	return mailtoLink;
};

export default LoanOfferTemplate;
