// Our Utils
import { dataIsValid } from 'shared/utils';
import sentenceCapitalize from 'shared/utils/formatting/sentenceCapitalize';

/**
 *
 * @param {string} offerType
 * @returns {string}
 */

function formatOfferType(offerType) {
	if (!dataIsValid(offerType)) return offerType;

	try {
		// has to be a string
		const separatedOfferType = offerType.split('_');

		// capitalize each word
		return separatedOfferType
			.map((word) => sentenceCapitalize(word))
			.join(' ');
	} catch (e) {
		return offerType;
	}
}

export default formatOfferType;
