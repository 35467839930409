import selectAdvisorDetails from 'selectors/LoanRequestDetails/selectAdvisorDetails';
import selectClientDetails from 'selectors/LoanRequestDetails/selectClientDetails';

const selectLoanDetailsSummary = (state) => {
	const clientDetails = selectClientDetails(state);
	const advisorDetails = selectAdvisorDetails(state);

	return {
		...clientDetails,
		...advisorDetails
	};
};

export default selectLoanDetailsSummary;
