import PropTypes from 'prop-types';
import { useMemo } from 'react';

import Input from 'components/Input/TextInput';

// Input Types
import { EMAIL } from 'components/Input/Types';

// Utils
import isValidEmail from 'shared/utils/verification/isValidEmail';

// Constants
import CommonPropTypes from 'shared/prop-types';

function EmailTextInput({ email, handleEmailChange, sx }) {
	const isEmailValid = useMemo(() => isValidEmail(email), [email]);

	return (
		<Input
			id="email"
			label="Email"
			type={EMAIL}
			onChange={handleEmailChange}
			inputProps={{
				'data-test': 'email-input'
			}}
			value={email}
			error={!isEmailValid}
			helperText={
				!isEmailValid ? 'Please enter a valid email address' : ''
			}
			sx={{
				marginTop: 4,
				marginBottom: 2,
				...sx
			}}
		/>
	);
}

EmailTextInput.propTypes = {
	email: PropTypes.string.isRequired,
	handleEmailChange: PropTypes.func.isRequired,
	sx: CommonPropTypes.sx
};

EmailTextInput.defaultProps = {
	sx: {}
};

export default EmailTextInput;
