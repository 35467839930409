import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

// Our components
import Loader from 'components/Loader';

// Our endpoints
import { userProfile } from 'shared/api-urls';

// Our axios Instance
import axiosInstance from 'services/API/API';

// Our Routes
import { DASHBOARD_ROUTE, LOGIN_ROUTE } from 'routes';

function RoutingUser() {
	const navigate = useNavigate();

	const check = useMutation(
		async () => {
			const res = await axiosInstance.get(userProfile);
			const userProfileData = res.data.data;
			return userProfileData;
		},
		{
			onSuccess: () => navigate(DASHBOARD_ROUTE),
			onError: () => navigate('/login')
		}
	);

	const { isLoading, error, isSuccess } = check;

	// useEffect is used to fix error Warning: Cannot update a component(`BrowserRouter`) while rendering a different component
	// Documentation for fix - https://github.com/facebook/react/issues/18178#issuecomment-595846312

	useEffect(() => {
		check.mutate({});
	}, []);

	if (isLoading)
		return (
			<>
				<Helmet>
					<title>Sora Routing User</title>
				</Helmet>
				<Loader
					boxSX={{
						width: '100%',
						height: '100vh',
						alignItems: 'center',
						justifyContent: 'center'
					}}
					size={100}
				/>
			</>
		);

	if (error) {
		navigate(LOGIN_ROUTE);
	}

	return (
		<>
			<Helmet>
				<title>Sora Routing User</title>
			</Helmet>
			<Loader
				boxSX={{
					width: '100%',
					height: '100vh',
					alignItems: 'center',
					justifyContent: 'center'
				}}
				size={100}
			/>
		</>
	);
}

export default RoutingUser;
