import { Grid } from '@mui/material';
import PropTypes from 'prop-types';

// Our Components
import ButtonDropdown from 'components/Button/ButtonDropdown';

// Our PropTypes
import CommonPropTypes from 'shared/prop-types';

// CONSTANTS
import { ADMIN_STATUS_MAPPING, ADMIN_STATUSES_LIST } from 'shared/constants';

/**
 * Retrieves the next and previous status based on the given statusEnum.
 *
 * @param {typeof ADMIN_STATUSES_LIST} statusEnum - An array of status values.
 * @returns {Array} An array containing the next and previous status values.
 */
function getNextAndPreviousStatus(STATUS_ENUM) {
	const statusIndex = ADMIN_STATUSES_LIST.findIndex(
		(step) => step === STATUS_ENUM
	);

	const isFirstStatus = statusIndex === 0;

	const POTENTIAL_NEXT_STATUS =
		ADMIN_STATUS_MAPPING[ADMIN_STATUSES_LIST[statusIndex + 1]];

	if (isFirstStatus) return [POTENTIAL_NEXT_STATUS];

	const nextIndex = (statusIndex + 1) % ADMIN_STATUSES_LIST.length;

	const previousIndex =
		(statusIndex - 1 + ADMIN_STATUSES_LIST.length) %
		ADMIN_STATUSES_LIST.length;

	// Enums
	const nextStatusEnum = ADMIN_STATUSES_LIST[nextIndex];
	const previousStatusEnum = ADMIN_STATUSES_LIST[previousIndex];

	// Values
	const nextStatus = ADMIN_STATUS_MAPPING[nextStatusEnum];
	const previousStatus = ADMIN_STATUS_MAPPING[previousStatusEnum];

	return [nextStatus, previousStatus];
}

function AdminLoanStatusDropdown({
	gridColumns,
	sx,
	onChange,
	status,
	isAllStatusesEnabled
}) {
	const statusOptions = isAllStatusesEnabled
		? ADMIN_STATUSES_LIST.map((status) => ADMIN_STATUS_MAPPING[status])
		: getNextAndPreviousStatus(status);

	return (
		<Grid item xs={gridColumns} sx={sx}>
			<ButtonDropdown
				menuOptions={statusOptions}
				onChange={onChange}
			></ButtonDropdown>
		</Grid>
	);
}

AdminLoanStatusDropdown.propTypes = {
	gridColumns: PropTypes.number,
	sx: CommonPropTypes.sx,
	tabIndex: PropTypes.string
};

AdminLoanStatusDropdown.defaultProps = {
	gridColumns: 5,
	sx: {},
	tabIndex: ''
};

export default AdminLoanStatusDropdown;
