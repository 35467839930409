import { useCallback, useMemo } from 'react';

// Our Components
import RequestDetailsItem from 'components/LoanRequestDetails/RequestDetails/RequestDetailsItem';

// Our Hooks
import useGetLoanRequestDetails from 'hooks/newLoanInquiries/useGetLoanRequestDetails';

// Our Selectors
import selectLoanOfferDetails from 'selectors/LoanRequestDetails/selectLoanOfferDetails';

// Our Utils
import { dollarFormatter } from 'shared/utils';

function OfferSelectedRequestDetails() {
	const urlString = window.location.search;
	const urlParams = new URLSearchParams(urlString);
	const loanRequestId = urlParams.get('loanRequestId');

	const memoizedSelectLoanOfferDetails = useCallback(
		selectLoanOfferDetails,
		[]
	);

	const { data } = useGetLoanRequestDetails(
		loanRequestId,
		memoizedSelectLoanOfferDetails
	);

	const {
		lender: offerLender,
		loanTerm: offerLoanTerm,
		rate: offerAnnualInterest,
		monthlyPayment: offerMonthlyPayment,
		soraFeeAmount: offerSoraFee,
		feeType: offerSoraFeeType,
		upfrontCost: offerLenderFees
	} = data;

	const formattedAnnualInterest = useMemo(
		() => `${offerAnnualInterest}%`,
		[offerAnnualInterest]
	);

	const formattedMonthlyPayment = useMemo(
		() => dollarFormatter.format(offerMonthlyPayment ?? 0),
		[offerMonthlyPayment]
	);

	const formattedLenderFees = useMemo(
		() => dollarFormatter.format(offerLenderFees ?? 0),
		[offerLenderFees]
	);

	const formattedSoraFee = useMemo(
		() => dollarFormatter.format(offerSoraFee ?? 0),
		[offerSoraFee]
	);

	return (
		<>
			<RequestDetailsItem
				heading="Offer selected Lender"
				value={offerLender}
			/>
			<RequestDetailsItem
				heading="Offer selected Loan Term"
				value={offerLoanTerm}
			/>
			<RequestDetailsItem
				heading="Offer selected annual interest"
				value={formattedAnnualInterest}
			/>
			<RequestDetailsItem
				heading="Offer selected monthly payment"
				value={formattedMonthlyPayment}
			/>
			<RequestDetailsItem
				heading="Offer selected lender fees"
				value={formattedLenderFees}
			/>
			<RequestDetailsItem
				heading="Offer selected Sora fee type"
				value={offerSoraFeeType}
			/>
			<RequestDetailsItem
				heading="Offer selected Sora fee"
				value={formattedSoraFee}
			/>
		</>
	);
}

export default OfferSelectedRequestDetails;
