import { useState } from 'react';
import { useTheme } from '@emotion/react';
import { Menu, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { v4 as uuidv4 } from 'uuid';

// Our components
import Alert from 'components/Alert';
import { TertiaryButton } from 'components/Button/Button';

function ButtonDropdown({ menuOptions, onChange }) {
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;
	const [showAlert, setShowAlert] = useState(false);
	const [popoverOpen, setPopoverOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);

	const handleMenuOpen = (e) => {
		setAnchorEl(e.currentTarget);
		setPopoverOpen(true);
	};

	const handleMenuClose = () => {
		setPopoverOpen(false);
		setShowAlert(false);
	};

	// based on the selected menu item, we set the template text to the correct template to then be displayed within a modal
	const handleMenuItemSelect = (e) => {
		setPopoverOpen(false);
		setShowAlert(false);
		onChange(e.target.innerText);
	};

	return (
		<>
			{showAlert && <Alert variant="success" />}
			<TertiaryButton
				ref={anchorEl}
				id="basic-button"
				aria-controls={popoverOpen ? 'basic-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={popoverOpen ? 'true' : undefined}
				onClick={handleMenuOpen}
			>
				Update status
				<KeyboardArrowDownIcon
					sx={{ color: primary.white, marginLeft: 1 }}
				/>
			</TertiaryButton>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={popoverOpen}
				onClose={handleMenuClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button'
				}}
			>
				{menuOptions.map((option) => (
					<MenuItem
						key={uuidv4()}
						onClick={handleMenuItemSelect}
						sx={{ padding: 2 }}
					>
						{option}
					</MenuItem>
				))}
			</Menu>
		</>
	);
}

ButtonDropdown.propTypes = {
	menuOptions: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default ButtonDropdown;
