import { Box, Grid, Typography } from '@mui/material';
import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

// Our Components
import CurrencyTextInput from 'components/Input/CurrencyTextInput';
import Dropdown from 'components/Dropdown/Dropdown';
import LenderInput from 'components/Input/LenderInput';
import LoanTermDropdown from 'components/Forms/AdminLoanOfferForms/LoanTermDropdown';
import Loader from 'components/Loader/index';
import RateInput from 'components/Input/RateInput';
import { TertiaryButton } from 'components/Button/Button';

// Our Hooks
import useMutateAddLoanOffer from 'hooks/newLoanInquiries/loanOffer/useMutateAddLoanOffer';
import useMutateEditLoanoffer from 'hooks/newLoanInquiries/loanOffer/useMutateEditLoanOffer';

// Our Utils
import { isSubmissionReady } from 'shared/utils';
import parseLoanTerm from 'shared/utils/parse/parseLoanTerm';

// Our Constants
import { MORTGAGE } from 'shared/constants';

const FEE_TYPES = ['Paid by borrower', 'Paid by lender'];
const NUMERIC_FORM_VALUES = ['upfrontCost', 'monthlyPayment', 'soraFeeAmount'];

const makeLoanTermSafe = (cTermYear, interestRateType) => {
	if (cTermYear !== '' && interestRateType !== '')
		return `${cTermYear} Year ${interestRateType}`;
	return '';
};

function AddLoanOfferForm({
	variant,
	selectedLoanOfferData,
	loanType,
	postSubmitHandler,
	tradeLineType
}) {
	const isNotMortgage = tradeLineType !== MORTGAGE;

	const urlString = window.location.search;
	const urlParams = new URLSearchParams(urlString);
	const loanRequestId = urlParams.get('loanRequestId');

	const parsedLoanTerm = useMemo(() => {
		if (!selectedLoanOfferData) return '';
		if (variant === 'add') return '';
		if (!isNotMortgage) return '';
		return parseLoanTerm(selectedLoanOfferData.loanTerm);
		// should have a value here for edit.
	}, [selectedLoanOfferData]);

	const [lender, setLender] = useState(selectedLoanOfferData?.lender ?? '');
	const [loanAmount, setLoanAmount] = useState(
		selectedLoanOfferData?.loanAmount ?? ''
	);
	const [monthlyPay, setMonthlyPay] = useState(
		selectedLoanOfferData?.monthlyPayment ?? ''
	);
	const [rate, setRate] = useState(`${selectedLoanOfferData?.rate ?? ''}`);
	const [oneTimeFee, setOneTimeFee] = useState(
		selectedLoanOfferData?.upfrontCost ?? ''
	);

	const [ownershipTenure, setOwnershipTenure] = useState(
		selectedLoanOfferData?.loanTerm ?? ''
	);

	const [termYear, setTermYear] = useState(parsedLoanTerm?.year ?? '');

	const [interestRateType, setInterestRateType] = useState(
		parsedLoanTerm?.type ?? ''
	);

	const [feeType, setFeeType] = useState(
		selectedLoanOfferData?.feeType ?? ''
	);
	const [soraFeeAmount, setSoraFeeAmount] = useState(
		selectedLoanOfferData?.soraFeeAmount ?? ''
	);

	const isAddVersion = useMemo(() => variant === 'add', [variant]);
	const isEditVersion = useMemo(() => variant === 'edit', [variant]);

	const requiredFormData = [lender, loanAmount, rate];

	const isFormReady = useMemo(
		() => isSubmissionReady(requiredFormData),
		requiredFormData
	);

	// API Hooks
	const addLoanOffer = useMutateAddLoanOffer();
	const editLoanoffer = useMutateEditLoanoffer();

	const isLoading = addLoanOffer.isLoading || editLoanoffer.isLoading;

	const handleFormSubmission = () => {
		const loanTermWithFallback = isNotMortgage
			? makeLoanTermSafe(termYear, interestRateType)
			: ownershipTenure;

		if (isAddVersion) {
			const addLoanOfferPayload = {
				newLoanId: loanRequestId,
				lender,
				loanTerm: loanTermWithFallback,
				rate,
				upfrontCost: oneTimeFee,
				monthlyPayment: monthlyPay,
				loanAmount,
				feeType,
				soraFeeAmount
			};
			// Make Numeric Values Safe
			NUMERIC_FORM_VALUES.map((key) => {
				const addLoanOfferValue = addLoanOfferPayload[key];
				const isNumericValueEmpty = addLoanOfferValue === '';

				if (isNumericValueEmpty) addLoanOfferPayload[key] = null;
			});

			addLoanOffer.mutate(addLoanOfferPayload, {
				onSuccess: postSubmitHandler
			});
		}

		if (isEditVersion) {
			const editLoanOfferPayload = {
				newLoanOfferId: selectedLoanOfferData.loanOfferId,
				newLoanId: loanRequestId,
				lender,
				loanTerm: loanTermWithFallback,
				rate,
				upfrontCost: oneTimeFee,
				monthlyPayment: monthlyPay,
				loanAmount,
				feeType,
				soraFeeAmount
			};

			// Make Numeric Values Safe
			NUMERIC_FORM_VALUES.map((key) => {
				const editLoanOfferValue = editLoanOfferPayload[key];
				const isNumericValueEmpty = editLoanOfferValue === '';

				if (isNumericValueEmpty) editLoanOfferPayload[key] = null;
			});

			editLoanoffer.mutate(editLoanOfferPayload, {
				onSuccess: postSubmitHandler
			});
		}
	};

	return (
		<>
			<Grid
				component="form"
				spacing={2}
				container
				noValidate
				autoComplete="off"
			>
				<Grid item xs={6}>
					<Box>
						<Typography
							variant="subtitle1"
							sx={{ marginBottom: 1 }}
						>
							Loan Type
						</Typography>
						<Typography variant="body1">
							{loanType ?? 'NA'}
						</Typography>
					</Box>
				</Grid>

				{/* This has dynamic grid columns */}
				<LoanTermDropdown
					ownershipTenure={ownershipTenure}
					setOwnershipTenure={setOwnershipTenure}
					interestRateType={interestRateType}
					setInterestRateType={setInterestRateType}
					termYear={termYear}
					setTermYear={setTermYear}
					isMortgage={tradeLineType === 'MORTGAGE'}
				/>

				<Grid item xs={6}>
					<CurrencyTextInput
						required
						label="Loan Amount"
						value={loanAmount}
						onChange={setLoanAmount}
					/>
				</Grid>
				<Grid item xs={6}>
					<LenderInput
						required
						lender={lender}
						setLender={setLender}
					/>
				</Grid>

				<Grid item xs={6}>
					<CurrencyTextInput
						label="Monthly Pay"
						value={monthlyPay}
						onChange={setMonthlyPay}
					/>
				</Grid>
				<Grid item xs={6}>
					<RateInput
						required
						label="APR"
						subLabel=""
						rate={rate}
						setRate={setRate}
					/>
				</Grid>
				<Grid item xs={6}>
					<CurrencyTextInput
						label="One time fees"
						value={oneTimeFee}
						onChange={setOneTimeFee}
					/>
				</Grid>
				<Grid item xs={6}>
					<Dropdown
						items={FEE_TYPES}
						label="Fee Type"
						onChange={setFeeType}
						selected={feeType}
						variant="outlined"
					/>
				</Grid>
				<Grid item xs={6}>
					<CurrencyTextInput
						label="Sora Fee Amount"
						value={soraFeeAmount}
						onChange={setSoraFeeAmount}
					/>
				</Grid>
			</Grid>

			{!isLoading && (
				<TertiaryButton
					isDisabled={!isFormReady}
					type="submit"
					onClick={handleFormSubmission}
					sx={{
						marginTop: 4
					}}
				>
					{isAddVersion ? 'Add Loan' : 'Save'}
				</TertiaryButton>
			)}
			{isLoading && <Loader boxSX={{ marginTop: 2 }} />}
		</>
	);
}

AddLoanOfferForm.propTypes = {
	variant: PropTypes.oneOf(['add', 'edit']),
	selectedLoanOfferData: PropTypes.shape({
		id: PropTypes.string,
		clientId: PropTypes.number,
		newLoanId: PropTypes.number,
		lender: PropTypes.string,
		loanType: PropTypes.string,
		rate: PropTypes.number,
		upfrontCost: PropTypes.number,
		monthlyPayment: PropTypes.number,
		lastReviewed: PropTypes.string,
		feeType: PropTypes.string,
		soraFeeAmount: PropTypes.number,
		activationDate: PropTypes.string,
		expirationDate: PropTypes.string,
		offeredBy: PropTypes.string
	}),
	loanType: PropTypes.string,
	loanTerm: PropTypes.string
};

AddLoanOfferForm.defaultProps = {
	variant: 'add',
	loanType: 'NA',
	loanTerm: 'NA'
};

export default AddLoanOfferForm;
