import { Grid } from '@mui/material/index';
import { useState } from 'react';

// Our Components
import Alert from 'components/Alert';
import Loader from 'components/Loader/index';
import TextInput from 'components/Input/TextInput';
import { TEXT } from 'components/Input/Types';
import { PrimaryButton } from 'components/Button/Button';

// Our Hooks
import useMutateUpdateUserPassword from 'hooks/useMutateUpdateUserPassword';

function ChangePassword() {
	const updatePassword = useMutateUpdateUserPassword();

	const [newPassword, setNewPassword] = useState('');
	const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');

	const {
		isError: updatePasswordError,
		isSuccess: updatePasswordIsSuccess,
		isLoading
	} = updatePassword;

	const bothPasswordsAreNotEmpty =
		newPassword.length > 7 && newPasswordConfirmation.length > 7;

	const bothPasswordAreSame = newPassword === newPasswordConfirmation;

	const handleUpdatePassword = () => {
		if (bothPasswordsAreNotEmpty && bothPasswordAreSame) {
			updatePassword.mutate({ newPassword });
		}
	};

	return (
		<Grid
			component="form"
			container
			spacing={2}
			onSubmit={handleUpdatePassword}
			noValidate
			autoComplete="off"
		>
			{updatePasswordError && <Alert variant="error" />}
			{updatePasswordIsSuccess && <Alert variant="success" />}
			<Grid item xs={6}>
				<TextInput
					label="New Password"
					onChange={setNewPassword}
					value={newPassword}
					type={TEXT}
					error={
						newPassword.length < 8 ||
						newPassword !== newPasswordConfirmation
					}
					helperText={
						// I don't love this solution as it's not very readable, but I was able to get it working quickly
						// eslint-disable-next-line no-nested-ternary
						newPassword.length < 8
							? 'Password must be minimum of 8 characters'
							: newPassword !== newPasswordConfirmation
								? 'New passwords must be the same'
								: ''
					}
					sx={{
						marginBottom: 4
					}}
					withVisibleToggle
					defaultVisibility={false}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextInput
					label="New Password Confirmation"
					onChange={setNewPasswordConfirmation}
					value={newPasswordConfirmation}
					error={
						newPasswordConfirmation.length < 8 ||
						newPassword !== newPasswordConfirmation
					}
					helperText={
						// eslint-disable-next-line no-nested-ternary
						newPassword.length < 8
							? 'Password must be minimum of 8 characters'
							: newPassword !== newPasswordConfirmation
								? 'New passwords must be the same'
								: ''
					}
					type={TEXT}
					sx={{
						marginBottom: 4
					}}
					withVisibleToggle
					defaultVisibility={false}
				/>
			</Grid>
			<Grid item xs={12}>
				{isLoading && <Loader />}
				{!isLoading && (
					<PrimaryButton
						isDisabled={
							!bothPasswordsAreNotEmpty || !bothPasswordAreSame
						}
						type="submit"
						onClick={handleUpdatePassword}
					>
						Save
					</PrimaryButton>
				)}
			</Grid>
		</Grid>
	);
}

export default ChangePassword;
