import { Box, Typography } from '@mui/material';

// Our Assets
import SoraLogoBlack from 'assets/images/sora_logos/SoraLogoBlack.svg';

// Our Components
import PDFDivider from 'components/PortableDocumentFormats/Section/PDFDivider';

const SORA_LOGO_DIMENSIONS = 50;

function LoanDetailsSoraBrandingInfo() {
	return (
		<>
			<Box
				sx={{
					width: '100%',
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					height: 50
				}}
			>
				<Box
					component="img"
					src={SoraLogoBlack}
					sx={{
						width: SORA_LOGO_DIMENSIONS,
						height: SORA_LOGO_DIMENSIONS
					}}
				/>
				<Typography variant="body2">
					<a href="https://www.sorafinance.com">sorafinance.com</a>
				</Typography>
			</Box>
			<PDFDivider />
		</>
	);
}

export default LoanDetailsSoraBrandingInfo;
