import { format } from 'date-fns';

function formatDate(dateToFormat, formatString = 'MM/dd/yy') {
	try {
		return format(new Date(dateToFormat), formatString);
	} catch (e) {
		console.error(e);
		return dateToFormat;
	}
}

export default formatDate;
