import { Grid, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

// Our Components
import LoanTasks from 'pages/Tasks/LoanTasks';

// Main Component
function TasksTabs() {
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;

	const urlString = window.location.search;
	const urlParams = new URLSearchParams(urlString);

	const selectedTab = parseInt(urlParams.get('selectedTab'), 2);

	return (
		<>
			<Grid item xs={12}>
				<Typography
					variant="h1Gascogne"
					sx={{
						display: 'block',
						color: primary.indigo,
						marginBottom: 2,
						marginLeft: 1
					}}
				>
					Loans Requested
				</Typography>
			</Grid>

			<LoanTasks />
		</>
	);
}

export default TasksTabs;
