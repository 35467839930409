import { IconButton } from '@mui/material';
import {
	ThumbUp,
	ThumbUpOutlined,
	ThumbDown,
	ThumbDownOutlined
} from '@mui/icons-material';
import { useTheme } from '@emotion/react';

// Our Components
import Loader from 'components/Loader/index';

// Our Hooks
import useMutateUpdateDocumentStatus from 'hooks/newLoanInquiries/document/useMutateUpdateDocumentStatus';

// Our Utils
import { dataIsValid } from 'shared/utils';

// Our Constants
const ICON_COLOR_MAPPING = {
	up: {
		Approved: {
			color: 'green',
			Icon: ThumbUp
		},
		'Pending Approval': {
			color: 'grey',
			Icon: ThumbUpOutlined
		},
		Rejected: {
			color: 'grey',
			Icon: ThumbUpOutlined
		}
	},
	down: {
		Approved: {
			color: 'grey',
			Icon: ThumbDownOutlined
		},
		'Pending Approval': {
			color: 'grey',
			Icon: ThumbDownOutlined
		},
		Rejected: {
			color: 'red',
			Icon: ThumbDown
		}
	}
};

const PAYLOAD_MAPPING = {
	up: 'Approved',
	down: 'Rejected'
};

const FALL_BACK = {
	up: {
		color: 'grey',
		Icon: ThumbUpOutlined
	},
	down: {
		color: 'grey',
		Icon: ThumbDownOutlined
	}
};

function ApprovalButton({ variant, currentDocument }) {
	const SoraTheme = useTheme();

	const { primary } = SoraTheme.palette;

	const validDocument = dataIsValid(currentDocument?.documentStatus);

	const mapping = validDocument
		? ICON_COLOR_MAPPING[variant][currentDocument?.documentStatus]
		: FALL_BACK[variant];

	const { Icon, color } = mapping;

	const finalColor = primary[color] || color;

	const updateDocumentStatus = useMutateUpdateDocumentStatus();

	const { isLoading } = updateDocumentStatus;

	const clickHandler = (e) => {
		e.stopPropagation();
		if (validDocument) {
			const updatingDocumentToThisStatus = PAYLOAD_MAPPING[variant];
			updateDocumentStatus.mutate([
				{
					documentId: currentDocument.id,
					documentStatus: updatingDocumentToThisStatus
				}
			]);
		}
	};

	if (isLoading) return <Loader size={30} />;

	return (
		<IconButton onClick={clickHandler} disabled={!validDocument}>
			<Icon
				sx={{
					color: finalColor,
					opacity: validDocument ? 1 : 0.5,
					'&:hover': {
						opacity: 0.5
					}
				}}
			/>
		</IconButton>
	);
}

export default ApprovalButton;
