import { Divider } from '@mui/material';

function PDFDivider() {
	return (
		<Divider
			variant="middle"
			sx={{
				backgroundColor: '#000',
				width: '100%',
				margin: 0
			}}
		/>
	);
}

export default PDFDivider;
