import { useTheme } from '@emotion/react';

// Our Components
import PDFHeadingText from 'components/PortableDocumentFormats/Heading/PDFHeadingText';
import PDFSection from 'components/PortableDocumentFormats/Section/PDFSection';

function LoanDetailsSummaryHeading({ loanRequestId }) {
	const SoraTheme = useTheme();
	const { indigo } = SoraTheme.palette.primary;
	return (
		<PDFSection sx={{ paddingTop: 6, paddingBottom: 2 }}>
			<PDFHeadingText
				sx={{ color: indigo }}
				headingVariant="h2Gascogne"
				headingText={`Loan Request #${loanRequestId}`}
			/>
		</PDFSection>
	);
}

export default LoanDetailsSummaryHeading;
