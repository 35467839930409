import PropTypes from 'prop-types';
import { Box, CircularProgress } from '@mui/material';

// Our Props
import CommonPropTypes from 'shared/prop-types';

// MUI docs mention default size is 40
// doc - https://mui.com/material-ui/api/circular-progress/
// Prep to have spinners of different sizes
function Loader({ boxSX, loaderSX, size }) {
	return (
		<Box sx={{ ...boxSX, display: 'flex' }}>
			<CircularProgress sx={{ ...loaderSX }} size={size} />
		</Box>
	);
}

Loader.propTypes = {
	boxSX: CommonPropTypes.sx,
	loaderSX: CommonPropTypes.sx,
	size: PropTypes.number
};

Loader.defaultProps = {
	boxSX: {},
	loaderSX: {},
	size: 40
};

export default Loader;
