import { useMutation, useQueryClient } from 'react-query';

// Our axios instance
import axiosInstance from 'services/API/API';

// Our endpoint
import { userProfile } from 'shared/api-urls';

// Our Query keys
import {
	USER_DATA,
	USERPROFILEDATA,
	WALLET_OVERVIEW_DATA
} from 'shared/query-keys';

// Our Utils
import capitalizeUserProfile from 'shared/utils/formatting/capitalizeUserProfile';

function useMutateAddProfileData() {
	const queryClient = useQueryClient();

	return useMutation(
		USER_DATA,
		async (userData) => {
			// Guarantee the name is formatted :)
			const formattedUserData = capitalizeUserProfile(userData);

			const response = await axiosInstance.post(
				userProfile,
				formattedUserData
			);

			const userProfileData = response.data.data;

			await queryClient.invalidateQueries(WALLET_OVERVIEW_DATA);

			return userProfileData;
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(USERPROFILEDATA);
			}
		}
	);
}

export default useMutateAddProfileData;
