// MUI icons
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

// Our Components
import DynamicNavMenu from 'components/NavMenu/NavBarComponents/DynamicNavMenu';

// Our Routes
import { SETTINGS_ROUTE, METRICS_ROUTE } from 'routes';

// Our Hooks
import useMutateSignOutUser from 'hooks/useMutateSignOutUser';

// Our Constants
const NAV_SETTINGS_OPTIONS = [
	{
		route: SETTINGS_ROUTE,
		Icon: SettingsIcon,
		navText: 'Settings',
		isExternal: false
	}
];

function NavSettings({ isMobile }) {
	const signOutUser = useMutateSignOutUser();

	return (
		<DynamicNavMenu
			isMobile={isMobile}
			navMenuOptions={[
				...NAV_SETTINGS_OPTIONS,
				{
					// Metrics page is the login page too.
					route: METRICS_ROUTE,
					Icon: LogoutOutlinedIcon,
					navText: 'Logout',
					isExternal: true,
					onClickCallback: () => signOutUser.mutate({})
				}
			]}
			colorOverride={isMobile ? null : 'black'}
		/>
	);
}

export default NavSettings;
