import { Box } from '@mui/material';
import { useTheme } from '@emotion/react';

// Our Components
import DynamicNavMenu from 'components/NavMenu/NavBarComponents/DynamicNavMenu';
import NavSettings from 'components/NavMenu/NavBarComponents/NavSettings';
import LoadingSkeleton from 'components/Frame/LoadingSkelton';
import TopNavMenuAdvisor from 'components/NavMenu/TopNavMenuAdvisor';

// Hooks
import useGetProfile from 'hooks/useGetProfile';

function DesktopLeftNav() {
	const SoraTheme = useTheme();

	const { adminLinearGradient } = SoraTheme.palette.gradient;

	const { isLoading, isSuccess, data } = useGetProfile();

	if (isLoading) {
		return (
			<Box
				sx={{
					background: adminLinearGradient,
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between'
				}}
			>
				<Box sx={{ alignSelf: 'center' }}>
					<LoadingSkeleton />
				</Box>

				<NavSettings />
			</Box>
		);
	}

	if (isSuccess) {
		const { profilePicUrl: advisorProfilePic, firstName, lastName } = data;

		return (
			<Box
				sx={{
					background: adminLinearGradient,
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between'
				}}
			>
				<Box
					sx={{
						display: 'flex',
						marginLeft: 6,
						justifyContent: 'left'
					}}
				>
					<TopNavMenuAdvisor
						firstName={firstName}
						lastName={lastName}
						companyName="Sora Finance"
						profilePicUrl={advisorProfilePic}
						sx={{ color: SoraTheme.palette.primary.white }}
					/>
				</Box>

				<Box sx={{ marginLeft: 6, marginRight: 6 }}>
					<hr style={{ color: SoraTheme.palette.primary.white }} />
				</Box>

				{/* TOP LIST */}
				<Box sx={{ marginLeft: 2 }}>
					<DynamicNavMenu />
				</Box>

				<Box
					sx={{
						display: 'flex',
						height: '100%'
					}}
				/>

				<NavSettings />
			</Box>
		);
	}
}

export default DesktopLeftNav;
