import { useMutation, useQueryClient } from 'react-query';

// our axios
import axiosInstance from 'services/API/API';

// Our Endpoints
import { deleteLoanOfferUrl } from 'shared/api-urls';

// our query keys
import { LOAN_REQUEST } from 'shared/query-keys';

function useMutateDeleteLoanOffer(closeModal) {
	const queryClient = useQueryClient();
	return useMutation(
		async (newLoanOfferId) => {
			const response = await axiosInstance.delete(
				`${deleteLoanOfferUrl}/${newLoanOfferId}`
			);
			return response.data;
		},
		{
			onSuccess: async () => {
				// user is shown success lightbox
				await queryClient.invalidateQueries(LOAN_REQUEST);
				closeModal();
			}
		}
	);
}

export default useMutateDeleteLoanOffer;
