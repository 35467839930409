import { useMutation, useQueryClient } from 'react-query';
import { getAuth, updatePassword } from 'firebase/auth';

// Our Query Keys
import { SUCCESS_MESSAGE_DATA, ERROR_MESSAGE_DATA } from 'shared/query-keys';

const useMutateUpdateUserPassword = () => {
	const queryClient = useQueryClient();
	const auth = getAuth();
	const user = auth.currentUser;

	return useMutation(
		({ newPassword }) =>
			// Calling firebase's updatePassword with the user and the new password
			updatePassword(user, newPassword).then((data) => data),
		{
			onSuccess: () => {
				queryClient.setQueryData(
					SUCCESS_MESSAGE_DATA,
					'You have successfully updated your password.'
				);
			},
			onError: (error) => {
				queryClient.setQueryData(ERROR_MESSAGE_DATA, error.message);
			}
		}
	);
};

export default useMutateUpdateUserPassword;
