import PropTypes from 'prop-types';
import { Grid, Typography, Paper, Stack } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useMemo } from 'react';

// Our Components
import AllLoanTypeRequestDetails from 'components/LoanRequestDetails/RequestDetails/AllLoanTypeRequestDetails';
import AutoSpecificRequestDetails from 'components/LoanRequestDetails/RequestDetails/AutoSpecificRequestDetails';
import MortgageSpecificRequestDetails from 'components/LoanRequestDetails/RequestDetails/MortgageSpecificRequestDetails';
import OfferSelectedRequestDetails from 'components/LoanRequestDetails/RequestDetails/OfferSelectedRequestDetails';
import PersonalSpecificRequestDetails from 'components/LoanRequestDetails/RequestDetails/PersonalSpecificRequestDetails';

// Our Utils
import { decodedLoanType } from 'shared/utils';

// Our Constants
import {
	AUTO,
	MORTGAGE,
	PERSONAL,
	NEW_LOAN_OFFER_TYPE
} from 'shared/constants';

function LoanRequestCard({
	city,
	currentRemainingTerm,
	employmentStatus,
	estimatedPurchaseValue,
	loanAmount,
	loanTenure,
	isLoanOfferAccepted,
	offerType,
	ownershipTenure,
	propertyType,
	propertyUse,
	state,
	totalAnnualIncome,
	totalAssetsValue,
	tradelineType,
	vehicleMileage,
	zipCode,
	sx
}) {
	const SoraTheme = useTheme();

	const { primary } = SoraTheme.palette;

	const loanType = useMemo(
		() => decodedLoanType(tradelineType, offerType),
		[tradelineType, offerType]
	);

	const isAutoLoan = useMemo(() => tradelineType === AUTO, [tradelineType]);

	const isPersonalLoan = useMemo(
		() => tradelineType === PERSONAL,
		[tradelineType]
	);

	const isMortgageLoan = useMemo(
		() => tradelineType === MORTGAGE,
		[tradelineType]
	);

	return (
		<Paper
			elevation={3}
			square={false}
			component={Stack}
			sx={{
				borderRadius: '20px',
				padding: 4,
				flexDirection: 'column',
				gap: 2,
				boxSizing: 'border-box',
				height: '100%',
				position: 'relative',
				...sx
			}}
		>
			<Typography
				variant="h2Gascogne"
				component="h2"
				gutterBottom
				sx={{ color: primary.indigo }}
			>
				Request Details
			</Typography>

			<Grid container>
				<AllLoanTypeRequestDetails
					loanAmount={loanAmount}
					ownershipTenure={ownershipTenure}
					loanType={loanType}
				/>

				{/* Auto Specific Request Details */}
				{isAutoLoan && (
					<AutoSpecificRequestDetails
						loanTenure={loanTenure}
						isLoanOfferAccepted={isLoanOfferAccepted}
						isNewAutoLoan={offerType === NEW_LOAN_OFFER_TYPE}
						currentRemainingTerm={currentRemainingTerm}
						estimatedPurchaseValue={estimatedPurchaseValue}
						vehicleMileage={vehicleMileage}
					/>
				)}

				{/* Mortgage Specific Request Details */}
				{isMortgageLoan && (
					<MortgageSpecificRequestDetails
						isNewMortgage={offerType === NEW_LOAN_OFFER_TYPE}
						isLoanOfferAccepted={isLoanOfferAccepted}
						estimatedPurchaseValue={estimatedPurchaseValue}
						city={city}
						propertyType={propertyType}
						propertyUse={propertyUse}
						state={state}
					/>
				)}

				{/* New Personal Loan */}
				{isPersonalLoan && (
					<PersonalSpecificRequestDetails
						totalAnnualIncome={totalAnnualIncome}
						totalAssetsValue={totalAssetsValue}
						employmentStatus={employmentStatus}
						state={state}
						zipCode={zipCode}
					/>
				)}

				{/* Offer Selected Request Details */}
				{isLoanOfferAccepted && <OfferSelectedRequestDetails />}
			</Grid>
		</Paper>
	);
}

LoanRequestCard.propTypes = {
	cardHeading: PropTypes.string
};

LoanRequestCard.defaultProps = {
	cardHeading: null
};

export default LoanRequestCard;
