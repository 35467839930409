import { useMemo } from 'react';

// Our Components
import RequestDetailsItem from 'components/LoanRequestDetails/RequestDetails/RequestDetailsItem';

// Our Utils
import { dollarFormatter } from 'shared/utils';

function MortgageSpecificRequestDetails({
	isNewMortgage,
	isLoanOfferAccepted,
	estimatedPurchaseValue,
	city,
	propertyType,
	propertyUse,
	state
}) {
	const formattedEstimatedPurchaseValue = useMemo(
		() => dollarFormatter.format(estimatedPurchaseValue ?? 0),
		[estimatedPurchaseValue]
	);
	return (
		<>
			{isLoanOfferAccepted && (
				<>
					<RequestDetailsItem
						heading="Property Type"
						value={propertyType ?? 'NA'}
					/>
					<RequestDetailsItem
						heading="Primary Use"
						value={propertyUse || 'NA'}
					/>
				</>
			)}

			{isNewMortgage && (
				<RequestDetailsItem
					heading="Estimated purchase value"
					value={formattedEstimatedPurchaseValue}
				/>
			)}

			<RequestDetailsItem heading="City" value={city} />
			<RequestDetailsItem heading="State" value={state} />
		</>
	);
}

export default MortgageSpecificRequestDetails;
