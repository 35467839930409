import PropTypes from 'prop-types';
import { useState, useRef } from 'react';
import { useReactToPrint } from 'react-to-print/lib/index';
import { Helmet } from 'react-helmet-async';
import { Box, Grid, Typography } from '@mui/material';

// Our Components
import AdminLoanStatusDropdown from 'components/Dropdown/AdminLoanStatusDropdown';
import BasicSwitch from 'components/Switches/BasicSwitch';
import LoadingSkeleton from 'components/Frame/LoadingSkelton';
import LoanDetailsSummaryPDF from 'components/PortableDocumentFormats/LoanDetailsSummaryPDF';
import { SecondaryButton } from 'components/Button/Button';

// Our Hooks
import useMutateUpdateLoanRequestStatus from 'hooks/newLoanInquiries/useMutateUpdateLoanRequestStatus';

// Our Constants
import { ADMIN_STATUS_MAPPING } from 'shared/constants';

// Our Prop Types
import CLIENT_DATA_PROP_TYPE from 'shared/PropTypes/LoanRequestDetailsHeading/ClientDataPropType';

const LoanRequestDetailsHeading = ({
	advisorName,
	clientData,
	isLoading,
	isLoanOfferAccepted,
	loanRequestType,
	loanRequestId,
	loanRequestStatus,
	loanRequestStatusText,
	lastUpdatedDate,
	newLoanDetails
}) => {
	const changeStatus = useMutateUpdateLoanRequestStatus();
	const [isSwitchChecked, setIsSwitchChecked] = useState(false);

	const loanDetailsSummaryPdfRef = useRef(null);

	const clientName = clientData?.clientName ?? '';

	const handlePrint = useReactToPrint({
		documentTitle: `Loan_Offer_#_${loanRequestId}_advisor_${advisorName}`,
		content: () => loanDetailsSummaryPdfRef.current
	});

	if (isLoading)
		return (
			<>
				<Helmet>
					<title>Loading Loan Request Details</title>
				</Helmet>
				<Grid container>
					<Grid item xs={7}>
						<Typography variant="h1Gascogne" gutterBottom>
							Loading Loan Request Details
						</Typography>
					</Grid>

					<Grid item xs={5}>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								gap: 2
							}}
						>
							<LoadingSkeleton sx={{ height: 40 }} />
							<LoadingSkeleton sx={{ height: 40 }} />
						</Box>
					</Grid>
				</Grid>
			</>
		);

	return (
		<>
			<Helmet>
				<title>Loan Request Details</title>
			</Helmet>
			<Grid container>
				<Grid item xs={7}>
					<Typography
						variant="h1Gascogne"
						component="h1"
						gutterBottom
						sx={{
							display: { xs: 'block', md: 'inline-block' }
						}}
					>
						{loanRequestType} | {loanRequestId}
					</Typography>
					<Typography
						variant="caption"
						component="p"
						gutterBottom
						sx={{
							marginBottom: 2,
							display: { xs: 'block', md: 'inline-block' },
							marginLeft: { xs: 0, md: 2 }
						}}
					>
						Last Updated: {lastUpdatedDate}
					</Typography>
				</Grid>
				<Grid item xs={5}>
					<Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
						<SecondaryButton
							isDisabled={!isLoanOfferAccepted}
							sx={{
								height: 40
							}}
							onClick={() => handlePrint()}
						>
							Download application
						</SecondaryButton>

						<BasicSwitch
							label="All Statuses"
							checked={isSwitchChecked}
							onChange={() =>
								setIsSwitchChecked(!isSwitchChecked)
							}
						/>

						<AdminLoanStatusDropdown
							isAllStatusesEnabled={isSwitchChecked}
							status={loanRequestStatus}
							onChange={(e) => {
								changeStatus.mutate({
									loanRequestId,
									loanRequestStatus: ADMIN_STATUS_MAPPING[e]
								});
							}}
						/>
					</Box>
				</Grid>
				<Grid
					item
					sx={{
						alignContent: 'flex-start',
						justifyContent: 'flex-end',
						marginBottom: 2
					}}
				>
					<Box sx={{ display: { xs: 'inherit', md: 'flex' } }}>
						<Typography
							variant="caption"
							component="p"
							gutterBottom
							sx={{ fontWeight: 'bold', marginRight: 1 }}
						>
							Client:
						</Typography>
						<Typography
							variant="caption"
							component="p"
							gutterBottom
							sx={{
								marginRight: 2,
								marginBottom: { xs: 2, md: 0 }
							}}
						>
							{clientName}
						</Typography>
						<Typography
							variant="caption"
							component="p"
							gutterBottom
							sx={{
								fontWeight: 'bold',
								marginRight: 1,
								marginTop: { xs: 2, md: 0 }
							}}
						>
							Advisor:
						</Typography>

						<Typography
							variant="caption"
							component="p"
							gutterBottom
						>
							{advisorName}
						</Typography>
					</Box>
				</Grid>

				<Grid item xs={12} sx={{ marginBottom: 2, paddingRight: 4 }}>
					<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
						<Typography
							variant="subtitle1"
							component="p"
							gutterBottom
							sx={{
								fontWeight: 'bold',
								marginRight: 1,
								marginTop: 1.5
							}}
						>
							Status:
						</Typography>
						<Typography variant="body1" gutterBottom>
							{loanRequestStatusText}
						</Typography>
					</Box>
				</Grid>
			</Grid>

			<LoanDetailsSummaryPDF
				isLoanOfferAccepted={isLoanOfferAccepted}
				clientData={clientData}
				loanRequestId={loanRequestId}
				newLoanDetails={newLoanDetails}
				ref={loanDetailsSummaryPdfRef}
			/>
		</>
	);
};

LoanRequestDetailsHeading.propTypes = {
	clientData: CLIENT_DATA_PROP_TYPE,
	isLoading: PropTypes.bool,
	loanRequestType: PropTypes.string,
	loanRequestId: PropTypes.number,
	loanRequestStatus: PropTypes.string,
	loanRequestStatusText: PropTypes.string,
	lastUpdatedDate: PropTypes.string,
	newLoanDetails: PropTypes.object
};

export default LoanRequestDetailsHeading;
