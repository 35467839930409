import axios from 'axios';

const baseURL = `${process.env.REACT_APP_ADMIN_API_BASE_URL}/proxy`;

const axiosInstance = axios.create({
	baseURL,
	headers: {
		'Content-Type': 'application/json',
		'Access-Control-Allow-Credentials': true,
		Accept: 'application/json'
	},
	withCredentials: true
});

axiosInstance.interceptors.request.use(function (config) {
	config.url = `${config.baseURL}/${config.method}?endpoint=${config.url}`;
	return config;
});

export default axiosInstance;
