import { Box, Tab, Tabs } from '@mui/material';
import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import PropTypes from 'prop-types';

// Our Components
import a11yProps from 'components/Tabs/AccessibilityProps';
import ChangePassword from 'components/Settings/ChangePassword';
import SettingsForm from 'components/Forms/SettingsForm';
import TabPanel from 'components/Tabs/TabPanel';

// Our Query Keys
import { USERPROFILEDATA } from 'shared/query-keys';

// Main Component
function SettingsTabs({ tabPanelSelected, setTabPanelSelected }) {
	const queryClient = useQueryClient();
	const handleTabSelection = useCallback((e, value) => {
		setTabPanelSelected(value);
	}, []);

	// Retrieve role from react query
	const loggedInUserProfileData = queryClient.getQueryData(USERPROFILEDATA);
	const isAdvisor = loggedInUserProfileData?.role === 'ADVISOR';

	return (
		<Box
			sx={{
				marginTop: 6
			}}
		>
			<Box>
				<Tabs
					value={tabPanelSelected}
					onChange={handleTabSelection}
					aria-label="Settings tabs"
				>
					<Tab
						sx={{
							textTransform: 'capitalize',
							borderBottom: `0.0625rem solid #cdd5df`
						}}
						label="My Profile"
						{...a11yProps(0)}
					/>
					<Tab
						sx={{
							textTransform: 'capitalize',
							borderBottom: `0.0625rem solid #cdd5df`
						}}
						label="Change Password"
						{...a11yProps(1)}
					/>
					{isAdvisor && (
						<Tab
							sx={{
								textTransform: 'capitalize',
								borderBottom: `0.0625rem solid #cdd5df`
							}}
							label="Plan & Billing"
							{...a11yProps(2)}
						/>
					)}

					{isAdvisor && (
						<Tab
							sx={{
								textTransform: 'capitalize',
								borderBottom: `0.0625rem solid #cdd5df`
							}}
							label="Alerts"
							{...a11yProps(3)}
						/>
					)}
				</Tabs>
			</Box>

			<TabPanel value={tabPanelSelected} index={0} sx={{ paddingTop: 4 }}>
				<SettingsForm />
			</TabPanel>
			<TabPanel value={tabPanelSelected} index={1} sx={{ paddingTop: 4 }}>
				<ChangePassword />
			</TabPanel>
		</Box>
	);
}

SettingsTabs.propTypes = {
	tabPanelSelected: PropTypes.number.isRequired,
	setTabPanelSelected: PropTypes.func.isRequired
};

export default SettingsTabs;
