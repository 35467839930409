import { useEffect, useMemo } from 'react';
import { Box, Typography } from '@mui/material';

// Our Components
import LoadingSkeleton from 'components/Frame/LoadingSkelton';

// Our hooks
import useMutateLoanRequestDocumentDownload from 'hooks/newLoanInquiries/useMutateLoanRequestDocumentDownload';

// Our Constants
import mimeTypes from 'shared/mimeTypes.json';

const getFileExtension = (filename) =>
	filename.slice(filename.lastIndexOf('.'));

function PreviewImage({ children, documentData }) {
	const downloadDocument = useMutateLoanRequestDocumentDownload(false);

	const { isLoading, isSuccess, data } = downloadDocument;

	useEffect(() => {
		downloadDocument.mutate(documentData);
	}, []);

	const cachedImage = useMemo(() => {
		if (isSuccess && data) {
			const { fileContent, fileName } = data;
			const fileExt = getFileExtension(fileName);

			const determinedMineType = mimeTypes[fileExt];

			const downloadUrl = `data:${
				determinedMineType ?? 'application/octet-stream'
			};base64,${fileContent}`;
			return downloadUrl;
		}
		return null;
	}, [data]);

	if (isLoading || !data) return <LoadingSkeleton />;

	if (isSuccess) {
		return (
			<Box
				sx={{
					width: '100%',
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					gap: 4
				}}
			>
				<Box
					component="img"
					src={cachedImage}
					sx={{
						width: '50%',
						height: '50%',
						maxWidth: 300,
						maxHeight: 300
					}}
				/>
				{children}
			</Box>
		);
	}

	return (
		<Box>
			<Typography variant="body1">
				Failed to load PDF <br />
				<br />
				Please flag in UAT
			</Typography>
		</Box>
	);
}

export default PreviewImage;
