import { useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

// Our components
import Loader from 'components/Loader';

// Our endpoints
import { userLogin as userLoginUrl } from 'shared/api-urls';

// Our axios Instance
import adminAxiosInstance from 'services/API/ADMINAPI';

// Our Query keys
import {
	ERROR_MESSAGE_DATA,
	FIREBASEUSERCREDENTIALS,
	ROLE,
	USER_DATA
} from 'shared/query-keys';

// Routes
import { DASHBOARD_ROUTE, LOGIN_ROUTE } from 'routes';

function PostLoginRoutingUser() {
	const queryClient = useQueryClient();
	const navigate = useNavigate();

	// Retrieve access token for api call
	const fireBaseUserAccessToken = queryClient.getQueryData(
		FIREBASEUSERCREDENTIALS
	);

	// Retrieve role for api call
	const role = queryClient.getQueryData(ROLE);

	// Create capitalized version of role to pass to endpoint
	const capitalizedRole = role?.toUpperCase();

	const roleOrToken = { role: capitalizedRole };

	const {
		isLoading,
		error,
		isSuccess,
		data: userProfileData
	} = useQuery(USER_DATA, async () => {
		const { userToken } = fireBaseUserAccessToken;

		const loginResponse2 = await adminAxiosInstance.post(userLoginUrl, {
			firebaseIdToken: userToken,
			...roleOrToken
		});

		return loginResponse2.data.data;
	});

	// useEffect is used to fix error Warning: Cannot update a component(`BrowserRouter`) while rendering a different component
	// Documentation for fix - https://github.com/facebook/react/issues/18178#issuecomment-595846312

	useEffect(() => {
		if (isSuccess) {
			// This is successful
			navigate(DASHBOARD_ROUTE);
			return;
		}
	}, [userProfileData]);

	if (isLoading)
		return (
			<>
				<Helmet>
					<title>Sora Routing User</title>
				</Helmet>
				<Loader
					boxSX={{
						width: '100%',
						height: '100vh',
						alignItems: 'center',
						justifyContent: 'center'
					}}
					size={100}
				/>
			</>
		);

	if (error) {
		queryClient.setQueryData(ERROR_MESSAGE_DATA, error.message);
		navigate(LOGIN_ROUTE);
		return;
	}

	return (
		<>
			<Helmet>
				<title>Sora Routing User</title>
			</Helmet>
			<Loader
				boxSX={{
					width: '100%',
					height: '100vh',
					alignItems: 'center',
					justifyContent: 'center'
				}}
				size={100}
			/>
		</>
	);
}

export default PostLoginRoutingUser;
