import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// Our Components
import Loader from 'components/Loader';
import TopNavMenuAdvisor from 'components/NavMenu/TopNavMenuAdvisor';
import DynamicNavMenu from 'components/NavMenu/NavBarComponents/DynamicNavMenu';
import NavSettings from 'components/NavMenu/NavBarComponents/NavSettings';

// Our Hooks
import useGetProfile from 'hooks/useGetProfile';
import { isMobilePhone } from 'validator';

function MobileNavMenu() {
	const SoraTheme = useTheme();

	const { isLoading, error, data, isSuccess } = useGetProfile();

	if (isLoading) return <Loader boxSX={{ justifyContent: 'center' }} />;

	if (error) return `An error has occurred: ${error.message}`;

	if (isSuccess) {
		const {
			profilePicUrl: advisorProfilePic,
			companyName,
			firstName,
			lastName
		} = data;

		return (
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					height: '100vh',
					minHeight: '100vh',
					marginTop: 10,
					alignItems: 'center'
				}}
			>
				{/* TOP LIST */}
				<Box>
					<DynamicNavMenu isMobile />
				</Box>

				{/* BOTTOM LIST */}
				<Box>
					<NavSettings isMobile />

					<Box sx={{ textAlign: 'center', marginBottom: 2 }}>
						<TopNavMenuAdvisor
							firstName={firstName}
							lastName={lastName}
							companyName={companyName}
							profilePicUrl={advisorProfilePic}
							sx={{ color: SoraTheme.palette.primary.black }}
						/>
					</Box>
				</Box>
			</Box>
		);
	}
}

export default MobileNavMenu;
