import { useCallback, useMemo, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

// Our components
import EmailTextInput from 'components/Input/EmailTextInput';
import Modal from 'components/Modal/Modal';
import { SecondaryButton } from 'components/Button/Button';

// Email Templates
import LoanOfferTemplate from 'emailTemplates/LoanOfferTemplate';

// Our Utils
import { dollarFormatter } from 'shared/utils';
import formatOfferType from 'shared/utils/formatting/LoanInquiry/formatOfferType';
import isValidEmail from 'shared/utils/verification/isValidEmail';

/**
 * @typedef {Object} LoanOffer
 * @property {null|string} activationDate
 * @property {number} clientId
 * @property {null|string} expirationDate
 * @property {string} feeType
 * @property {number} id
 * @property {string} lastReviewed
 * @property {string} lender
 * @property {number} loanAmount
 * @property {string} loanTerm
 * @property {string} loanType
 * @property {number} monthlyPayment
 * @property {number} newLoanId
 * @property {string} offeredBy
 * @property {number} rate
 * @property {number} soraFeeAmount
 * @property {number} upfrontCost
 */

function RequestOfferModal({
	acceptedOfferId,
	clientData,
	loanType,
	newLoanOffers,
	offerType,
	state,
	isOpen,
	setIsModalOpen,
	sx
}) {
	const closeModal = useCallback(() => {
		setEmail('');
		setIsModalOpen(false);
	}, []);

	const [email, setEmail] = useState('');
	const isEmailValid = useMemo(() => isValidEmail(email), [email]);

	/** @type {LoanOffer} */
	const selectedLoanOfferData = newLoanOffers.find(
		(cId) => cId.id === acceptedOfferId
	);

	const { loanAmount, loanTerm } = selectedLoanOfferData;

	const formattedLoanAmount = dollarFormatter.format(loanAmount ?? 0);
	const formattedOfferType = formatOfferType(offerType ?? '');
	const formattedAnnualIncome = dollarFormatter.format(
		clientData?.annualIncome ?? 0
	);

	const loanOfferDetails = [
		{
			heading: 'Amount requested:',
			text: formattedLoanAmount
		},
		{ heading: 'Loan Term', text: loanTerm },
		{ heading: 'Type of request', text: formattedOfferType },
		{
			heading: "Client's credit score:",
			text:
				clientData?.creditScore !== '' ? clientData?.creditScore : 'NA'
		},
		{
			heading: "Client's State:",
			text: clientData?.stateLocation ?? 'NA'
		}
	];
	return (
		<Modal
			title="Selected Loan Offer Details"
			subtitle="Preview selected loan offer details below"
			isOpen={isOpen}
			handleClose={closeModal}
			sx={sx}
		>
			<Grid container spacing={2}>
				{loanOfferDetails.map(({ heading, text }) => (
					<Grid item xs={4} key={uuidv4()} sx={{ marginTop: 2 }}>
						<Typography
							variant="subtitle1"
							sx={{ color: 'black', marginBottom: 0.5 }}
						>
							{heading}
						</Typography>
						<Typography variant="body1" sx={{ color: 'black' }}>
							{text ?? 'NA'}
						</Typography>
					</Grid>
				))}

				<Grid item xs={12} sx={{ marginTop: 2 }}>
					<EmailTextInput
						email={email}
						handleEmailChange={setEmail}
					/>
				</Grid>
				<Grid item xs={12} sx={{ marginTop: 2 }}>
					<SecondaryButton
						disabled={!isEmailValid}
						onClick={() => {
							window.open(
								LoanOfferTemplate({
									loanAmount: formattedLoanAmount,
									loanTerm,
									loanType,
									offerType: formattedOfferType,
									creditScore: clientData?.creditScore,
									annualIncome: formattedAnnualIncome,
									state,
									emailData: {
										email,
										subject: `${loanType} loan offer opportunity from Sora`
									}
								})
							);

							closeModal();
						}}
					>
						Send
					</SecondaryButton>
				</Grid>
			</Grid>
		</Modal>
	);
}

export default RequestOfferModal;
