import { useMemo } from 'react';

// Our Components
import RequestDetailsItem from 'components/LoanRequestDetails/RequestDetails/RequestDetailsItem';

// Our Utils
import { dollarFormatter } from 'shared/utils';

function AllLoanTypeRequestDetails({ loanAmount, loanType, ownershipTenure }) {
	const safeLoanAmount = useMemo(() => {
		if (loanAmount) return dollarFormatter.format(loanAmount);
		return 'NA';
	}, [loanAmount]);

	return (
		<>
			<RequestDetailsItem heading="Loan Amount" value={safeLoanAmount} />

			{ownershipTenure && (
				<RequestDetailsItem
					heading="Loan Term"
					value={ownershipTenure}
				/>
			)}
			<RequestDetailsItem heading="Loan Type" value={loanType} />
		</>
	);
}

export default AllLoanTypeRequestDetails;
