import { Popup } from '@talkjs/react';

// Hooks
import useGetCurrentConversation from 'hooks/talkjs/queries/useGetCurrentConversation';

function TalkJsChatContainer({ advisorId, clientId, clientInfo }) {
	const {
		data: conversationId,
		isLoading,
		isSuccess
	} = useGetCurrentConversation(advisorId, clientId, clientInfo);

	if (isLoading) return null;

	if (isSuccess) {
		return (
			<Popup
				conversationId={conversationId}
				height={800}
				width={800}
				style={{ width: 800, height: 800 }}
				className="chat-container"
			/>
		);
	}

	return null;
}

export default TalkJsChatContainer;
